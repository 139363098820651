import React, { useState } from "react";
import { DeviceItem, Container, OpenContainer, SpacingContainer, StackContainer, IconContainer, DeviceNameStyled } from "./styles";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useTheme } from "../../../../contexts/theme";
import { useDevice } from "../../../../contexts/device";
import { StatusModal } from "../../../../components";
import { ReactComponent as PlusIcon } from "../../../../assets/images/plusIcon.svg";
import { ReactComponent as EditIconLight } from "../../../../assets/images/light/editIcon.svg";
import { ReactComponent as EditIconDark } from "../../../../assets/images/dark/editIcon.svg";
import { DropdownItem } from "./components";
import { MESSAGES } from "../../../../constants/messages";
import { DEFAULT_DEVICE, FEATURES, THEME_TYPES } from "../../../../constants";
import { Device } from "../../../../types/Device";
import { NewDeviceModal, ShowDevicesModal } from "../../../Modal";
import { useMobileApp } from "../../../../contexts/mobileApp";
import { useAdminUser } from "../../../../contexts/adminUser";
import { canManage, canSee } from "../../../../services/auth/PermissionGate";

interface Props {
	items: Device[];
	selected: Device | null;
	onChange: (value: Device) => void;
}

const DeviceSelectionDropdown = (props: Props) => {

	const { currentTheme } = useTheme();
	const { currentUser } = useAdminUser();
	const { 
		addDeviceModalVisible,
		editDeviceModalVisible,
		setAddDeviceModalVisible,
		setEditDeviceModalVisible } = useDevice();
	const { selectedApp } = useMobileApp();

	const [isOpen, setIsOpen] = useState(false);
	const [statusModal, setStatusModal] = useState({ isOpen: false, title: "", description: "", success: false });

	const onNeedShowStatus = (feedback) => {
		setStatusModal(feedback);
		setAddDeviceModalVisible(false);

		setTimeout(() => {
			setStatusModal({ ...statusModal, isOpen: false });
		}, 3000);
	};

	return (
		<StackContainer onMouseLeave={() => setIsOpen(false)} disabled={!canSee(currentUser.role, FEATURES.DEVICES)}>
			<StatusModal isOpen={statusModal.isOpen} title={statusModal.title} description={statusModal.description} success={statusModal.success} />
			{addDeviceModalVisible && <NewDeviceModal onClose={onNeedShowStatus} onCancel={() => setAddDeviceModalVisible(false)} />}
			{editDeviceModalVisible && <ShowDevicesModal onCancel={() => setEditDeviceModalVisible(false)} />}
			<Container onClick={() => canSee(currentUser.role, FEATURES.DEVICES) && setIsOpen(!isOpen)} >
				{props.selected?.slug === DEFAULT_DEVICE.slug ? 
					<img src={selectedApp.image ?? ""} /> 
					: <img src={props.selected?.imageCDN ?? ""} /> }
				<DeviceNameStyled textLength={props.selected?.name?.length || 0}>{props.selected?.name}</DeviceNameStyled>
				<div>
					{isOpen ?
						<IoIosArrowUp color={currentTheme.secondaryColor} fontSize="1.5em" /> :
						<IoIosArrowDown color={currentTheme.secondaryColor} fontSize="1.5em" />
					}
				</div>
			</Container>
			{isOpen &&
				<SpacingContainer>
					<OpenContainer >
						{props.items.map((item, index) => {
							return (
								<DropdownItem key={index} item={item} onClick={(selectedApp) => {
									setIsOpen(false);
									props.onChange(selectedApp);
								}} />
							);
						})}
						<DeviceItem onClick={() => canManage(currentUser.role, FEATURES.DEVICES) && setAddDeviceModalVisible(true)} enabled={canManage(currentUser.role, FEATURES.DEVICES) }>
							<IconContainer>
								<PlusIcon fill={currentTheme.secondaryColor} />
							</IconContainer>
							<h4>{MESSAGES.PT_BR.CONFIG.NEW_DEVICE}</h4>
						</DeviceItem>
						<DeviceItem onClick={() => canManage(currentUser.role, FEATURES.DEVICES) &&  setEditDeviceModalVisible(true)} enabled={canManage(currentUser.role, FEATURES.DEVICES) }>
							<IconContainer>
								{currentTheme.type == THEME_TYPES.DARK ? <EditIconDark /> : <EditIconLight />}
							</IconContainer>
							<h4>{MESSAGES.PT_BR.CONFIG.EDIT_DEVICE}</h4>
						</DeviceItem>
					</OpenContainer>
				</SpacingContainer>
			}
		</StackContainer>
	);
};

export default DeviceSelectionDropdown;