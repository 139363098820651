import { useTheme } from "../../../../../../../../contexts/theme";
import { InternalContentContainer, ModalContainer, ModalContent, ModalHeader } from "./styles";
import { ReactComponent as CloseIcon } from "../../../../../../../../assets/images/closeIcon.svg";
import { MESSAGES } from "../../../../../../../../constants/messages";
import { useEffect, useState } from "react";
import { FaqFeedbackTopicComment } from "../../../../../../../../types/FaqFeedback";
import { Helpers } from "../../../../../../../../helpers";
import { useMobileApp } from "../../../../../../../../contexts/mobileApp";
import { Api } from "../../../../../../../../services";
import { Loader } from "../../../../../../../../components";
import CommentCard from "./CommentCard";

interface Props {
	modalTopicId: number | null;
	closeCallback: () => void;
}

const FeedbackComments = ({
	modalTopicId,
	closeCallback,
}: Props) => {
	const { currentTheme } = useTheme();
	const { selectedApp } = useMobileApp();

	const [loading, setLoading] = useState(true);
	const [faqFeedbackTopicComments, setFaqFeedbackTopicComments] = useState<FaqFeedbackTopicComment[] | null>(null);

	useEffect(() => {
		if (modalTopicId === null) {
			setFaqFeedbackTopicComments(null);
			return;
		}

		let isMounted = true;

		Helpers.General.run(async () => {
			setLoading(true);

			try {
				const faqFeedbackTopicComments = await Api.FaqFeedback.getFaqFeedbackTopicComments(selectedApp?.appCode, modalTopicId);
				if (isMounted)
					setFaqFeedbackTopicComments(faqFeedbackTopicComments);
			} finally {
				if (isMounted)
					setLoading(false);
			}
		});

		return () => {
			isMounted = false;
		};
	}, [modalTopicId]);

	const getContent = () => {
		if (loading)
			return <Loader />;
	
		if (!faqFeedbackTopicComments)
			return <p>Erro ao buscar dados do backend.</p>;
	
		if (faqFeedbackTopicComments.length === 0)
			return <p>Não há comentários para este tópico no momento.</p>;
		
		return faqFeedbackTopicComments.map((item, index) =>
			<CommentCard key={index} faqFeedbackTopicComment={item} />
		);
	};

	if (modalTopicId === null)
		return null;

	return (
		<ModalContainer onClick={(event) => event.target === event.currentTarget && closeCallback()}>
			<ModalContent appTheme={currentTheme}>
				<ModalHeader appTheme={currentTheme}>
					<h1>{MESSAGES.PT_BR.FAQ_MESSAGES.FEEDBACK_COMMENTS_MODAL_TITME}</h1>
					<div>
						<CloseIcon onClick={closeCallback} />
					</div>
				</ModalHeader>
				<InternalContentContainer>
					{getContent()}
				</InternalContentContainer>
			</ModalContent>
		</ModalContainer>
	);
};

export default FeedbackComments;
