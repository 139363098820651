
/**
export interface WatchFace {
    id: number;
    name: string;
    image: string | null;
    preview: string | null;
    showApp: boolean;
    index?: number;
    downloadsCount?: number;
}

export interface WatchFaceSection {
    id: number;
    sectionName: string | null;
    watchFaces: WatchFace[];
    index?: number;
}

 Api Object - WatchFaceSection
 {
  "name": "string",
  "slug": "string",
  "index": 0
}
 */


const fromWatchFaceSectionModelToApi = (watchFaceSection, deviceSlug) => {
	return {
		name: watchFaceSection.sectionName,
		slug: deviceSlug,
		index: parseInt(watchFaceSection.index) || 0,
	};
};

/**
 * API Response
[
    {
      "id": 1,
      "name": "Teste",
      "orderSection": 0,
      "watchFaces": [
        {
          "id": 1,
          "name": "mostrador1",
          "image": "string",
          "preview": "string",
          "orderWatchFace": 0,
          "downloadCount": 0
        }
      ]
    }
  ]
 */

const fromApiToWatchSectionModel = (apiResponse) => {
	return {
		sectionName: apiResponse.name,
		id: apiResponse.id,
		index: apiResponse.orderSection,
		watchFaces: apiResponse?.watchFaces?.map((wf) => {
			return {
				id: wf.id,
				name: wf.name,
				sectionName: apiResponse.name,
				image: wf.imageS3,
				imageCDN: wf.image,
				preview: wf.previewS3,
				previewCDN: wf.preview,
				showApp: wf.showApp ?? false,
				defaultWatchface: wf.defaultWatchface ?? false,
				index: wf.orderWatchFace,
				downloadsCount: wf.downloadCount ? wf.downloadCount : 0,
				downloadCountWithinRange: wf.downloadCountWithinRange,
			};}) ?? [],
	};
};

/**
 * Api Object to Post WatchFace
{
  "name": "string",
  "slug": "string",
  "index": 0,
  "sectionId": 0,
  "showApp": true,
  "preview": "string",
  "image": "string",
  "model": "string"
}
 */
const fromWatchModelToApiModel = (watchFace, sectionId, deviceSlug) => {
	return {
		name: watchFace.name,
		slug: deviceSlug,
		index: watchFace.index || 0,
		sectionId: sectionId,
		showApp: watchFace.showApp ?? false,
		defaultWatchface: watchFace.defaultWatchface ?? false,
		preview: watchFace.preview,
		image: watchFace.image,
		model: "",
	};
};

export const WatchFaceMapper = {
	fromWatchFaceSectionModelToApi,
	fromApiToWatchSectionModel,
	fromWatchModelToApiModel
};