const fromApiToModel = (shareExercise) => {
	return {
		id: shareExercise.id,
		name: shareExercise.name,
		image: shareExercise.imageS3, // manter image com a url do S3 para não alterar valores no banco quando fizer update!
		imageCDN: shareExercise.image, // consumir imagem da CDN
		slugs : shareExercise.slugs,
		index: shareExercise.index,
		showApp: shareExercise.available
	};
};

const fromModelToApi = (shareExercise) => {
	return {
		name: shareExercise.name,
		image: shareExercise.image,
		available: shareExercise.showApp,
		slugs: shareExercise.slugs,
		index: shareExercise.index
	};
};

export const ShareExerciseMapper = {
	fromApiToModel,
	fromModelToApi
};
