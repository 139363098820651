import React, { useState } from "react";
import { FilesContainer, ImgContainer, LeftSide, ModalBody, ModalContainer, ModalContent, ModalFooter, ModalHeader, RightSide, ToogleContainer } from "./styles";
import { ReactComponent as CloseIcon } from "../../../../../../../assets/images/closeIcon.svg";
import { useTheme } from "../../../../../../../contexts/theme";
import { MESSAGES } from "../../../../../../../constants/messages";
import { FileInput, InfoInput, MainInput } from "../../../../../../../components/Inputs";
import { MainButton } from "../../../../../../../components/Buttons";
import { WatchFace, WatchFaceSection } from "../../../../../../../types/Watchface";
import { BiImage } from "react-icons/bi";
import { InfoBox, InfoWfBox, TechnosToggle } from "../../../../../../../components";
import { toast } from "react-hot-toast";
import { useMobileApp } from "../../../../../../../contexts/mobileApp";
import { useWatchFace } from "../../../../../../../contexts/watchFaces";
import { FIXED_SECTION_NAME } from "../../../../../../../constants";
import { WatchFaceType } from "../../../../../../../types/Device";
import { useDevice } from "../../../../../../../contexts/device";

interface ModalProps {
	onClose: (feedback) => void;
	currentSection: WatchFaceSection;
	currentWatchFace: WatchFace | null;
}

const EditWatchFaceModal = (props: ModalProps) => {
	const emptyFile = new File([], "");

	const { currentTheme } = useTheme();
	const { selectedApp } = useMobileApp();
	const { currentDevice } = useDevice();
	const { updateWatchFace } = useWatchFace();

	const [watchFace, setWatchFace] = useState<WatchFace>(props.currentWatchFace ?? {} as WatchFace);
	const [wfImage, setWfImage] = useState<File>(emptyFile);
	const [preview, setPreview] = useState<File>(emptyFile);
	const [previewImage, setPreviewImage] = useState<string>(props.currentWatchFace?.previewCDN ?? "");
	const [nameError, setNameError] = useState<string>("");

	const handleSaveNewWatchFace = () => {
		let allRight = true;

		if (watchFace.name === "") {
			setNameError(MESSAGES.PT_BR.WATCH_FACE.WATCH_FACES_MESSAGES.MANDATORY_FIELD);
			allRight = false;
		} else {
			setNameError("");
		}

		if (!allRight) return;

		const t = toast.loading(MESSAGES.PT_BR.WATCH_FACE.WATCH_FACES_MESSAGES.LOADING);
		updateWatchFace(selectedApp.appCode, watchFace, wfImage, preview, currentDevice?.slug ?? "")
			.then(() => {
				props.onClose({ isOpen: true, title: MESSAGES.PT_BR.WATCH_FACE.UPDATE_WATCH_FACE_FEEDBACK_MESSAGES.SUCCESS_TITLE, description: MESSAGES.PT_BR.WATCH_FACE.UPDATE_WATCH_FACE_FEEDBACK_MESSAGES.SUCCESS_DESCRIPTION, success: true });
			}).catch((error) => {
				props.onClose({ isOpen: true, title: MESSAGES.PT_BR.WATCH_FACE.UPDATE_WATCH_FACE_FEEDBACK_MESSAGES.ERROR_TITLE, description: MESSAGES.PT_BR.WATCH_FACE.UPDATE_WATCH_FACE_FEEDBACK_MESSAGES.ERROR_DESCRIPTION + error, success: false });
			}).finally(() => toast.dismiss(t));
	};

	const handleUploadImageFile = (file: File) => {
		setPreview(file);
		setPreviewImage(URL.createObjectURL(file));
	};

	const getFileInputPropsForWatchFace = () => {
		switch (currentDevice?.watchFaceType) {
		case WatchFaceType.ZIP:
			return {
				title: MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.ZIP,
				accept: ".zip",
				InfoBox: <InfoBox style={{ height: 50 }} text={MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.HELP_ZIP_FILE} />,
			};
		case WatchFaceType.WATCH:
			return {
				title: MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.WATCH,
				accept: ".watch",
				InfoBox: <InfoBox style={{ height: 50 }} text={MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.HELP_WATCH_FILE} />,
			};
		case WatchFaceType.BIN:
		default:
			return {
				title: MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.BIN,
				accept: ".bin",
				InfoBox: <InfoBox style={{ height: 50 }} text={MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.HELP_BIN_FILE} />,
			};
		}
	};

	const getNameDescription = () =>{
		if(currentDevice?.name === "Sports Call"){
			return MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.HELP_NAME_DESCRIPTION_SPORTS;
		}else {
			return  MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.HELP_NAME_DESCRIPTION_DEFAULT;
		}
	};

	return (
		<ModalContainer>
			<ModalContent>
				<ModalHeader>
					<h1>{MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.EDIT_TITLE}</h1>
					<div>
						<CloseIcon onClick={() => { props.onClose(null); }} />
					</div>
				</ModalHeader>
				<ModalBody>
					<LeftSide>
						<MainInput
							type="text"
							onChange={() => null}
							placeholder={""} value={props.currentSection?.sectionName ?? ""}
							title={MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.SECTION}
							disabled
						/>
						<InfoInput
							type="text"
							onChange={(newName) => setWatchFace({ ...watchFace, name: newName })}
							placeholder={MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.WF_NAME_PLACEHOLDER}
							value={watchFace.name}
							title={MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.WF_NAME}
							errorText={nameError}
							InfoBox={<InfoWfBox
								style={{ height: "200px" }}
								title1={MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.HELP_NAME_TITLE1}
								text1={MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.HELP_NAME_DESCRIPTION1}
								title2={MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.HELP_NAME_TITLE2}
								text2={getNameDescription()}
							/>}
						/>
						<FilesContainer>
							<div>
								<FileInput
									onChange={setWfImage}
									value={wfImage.name}
									valueLink={wfImage.size ? null : watchFace.imageCDN}
									{...getFileInputPropsForWatchFace()}
								/>
							</div>
							<h5> </h5>
							<div>
								<FileInput
									onChange={handleUploadImageFile}
									title={MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.WF_PREVIEW}
									accept="image/png, image/jpeg"
									value={preview.name}
									valueLink={preview.size ? null : watchFace.previewCDN}
									InfoBox={<InfoBox style={{ height: "90px" }} text={MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.HELP_PNG_FILE} />}
								/>
							</div>
						</FilesContainer>
					</LeftSide>
					<RightSide>
						<ImgContainer>
							<BiImage color={currentTheme.textColor} size={50} />
							{previewImage ? <img src={previewImage} /> : null}
						</ImgContainer>
						<ToogleContainer>
							<TechnosToggle
								defaultChecked={watchFace.showApp}
								leftLabel={MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.SHOW_ON_APP}
								onChange={() => setWatchFace({ ...watchFace, showApp: !watchFace.showApp })}
								checked={props.currentSection?.sectionName === FIXED_SECTION_NAME || watchFace.showApp}
								disabled={props.currentSection?.sectionName === FIXED_SECTION_NAME}
							/>
							<TechnosToggle
								defaultChecked={watchFace.defaultWatchface}
								leftLabel={MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.DEFAULT_WHATCHFACE}
								onChange={() => setWatchFace({ ...watchFace, defaultWatchface: !watchFace.defaultWatchface })}
								checked={props.currentSection?.sectionName === FIXED_SECTION_NAME || watchFace.defaultWatchface}
								disabled={props.currentSection?.sectionName === FIXED_SECTION_NAME}
							/>
						</ToogleContainer>
					</RightSide>
				</ModalBody>
				<ModalFooter>
					<p>{MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.ADD_AND_CREATE_NEW}</p>
					<MainButton
						enabled
						onClick={() => handleSaveNewWatchFace()}
						text={MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.SAVE}
						lightColor={currentTheme.primaryColor}
						darkColor={currentTheme.primaryColor}
					/>
				</ModalFooter>
			</ModalContent>
		</ModalContainer>
	);

};

export default EditWatchFaceModal;