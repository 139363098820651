import React, { useState } from "react";
import { MESSAGES } from "../../../../constants/messages";
import { PhotoBox, StatusModal } from "../../../../components";
import { MainButton } from "../../../../components/Buttons";
import { FileInput } from "../../../../components/Inputs";
import { COLORS } from "../../../../assets";
import { Content, ContentFooter, Preview } from "./styles";
import { useDevice } from "../../../../contexts/device";
import { toast } from "react-hot-toast";
import { useMobileApp } from "../../../../contexts/mobileApp";

const Manual = () => {
	const { currentDevice, updateDevice } = useDevice();
	const { selectedApp } = useMobileApp();

	const currentPdf = currentDevice?.linkManualCDN || "";
	const [manualFile, setManualFile] = useState(new File([], ""));
	const [modalStatus, setModalStatus] = useState({ isOpen: false, title: MESSAGES.PT_BR.MANUAL.FEEDBACK_TITLE, description: MESSAGES.PT_BR.MANUAL.FEEDBACK_DESCRIPTION, success: false });

	const handleOnClickSave = () => {
		if(currentDevice != null){
			const t = toast.loading(MESSAGES.PT_BR.MANUAL.LOADING);
			updateDevice(currentDevice, manualFile, selectedApp.appCode, "manual")
				.then(() => {
					setModalStatus({ isOpen: true, title: MESSAGES.PT_BR.MANUAL.FEEDBACK_TITLE, description: MESSAGES.PT_BR.MANUAL.FEEDBACK_DESCRIPTION, success: true });
				})
				.catch((error) => {
					setModalStatus({ isOpen: true, title: MESSAGES.PT_BR.MANUAL.AWS_ERROR, description: MESSAGES.PT_BR.MANUAL.DEFUALT_ERROR + error, success: false });
				})
				.finally(() => {
					toast.dismiss(t);
					setTimeout(() => {
						setModalStatus({ ...modalStatus, isOpen: false });
					}, 3000);
				});
		}
	};

	return (
		<Content>
			<StatusModal isOpen={modalStatus.isOpen} description={modalStatus.description} success={modalStatus.success} title={modalStatus.title} />
			<Preview hasManualAttached={currentPdf.length > 0}>
				<h2>Preview</h2>
				{currentPdf ?
					<div>
						<embed src={currentPdf} width="100%" height="100%" type="application/pdf"></embed>
					</div>
					:
					<></>
				}
			</Preview>

			<ContentFooter>
				<FileInput
					onChange={setManualFile}
					title={"Escolher manual"}
					InfoBox={<PhotoBox format={MESSAGES.PT_BR.MANUAL.FILE_FORMAT} maximumSize={MESSAGES.PT_BR.MANUAL.FILE_MAX_SIZE} />}
					accept="application/pdf"
					value={manualFile.name}
					valueLink={manualFile.size ? null : currentPdf}
					style={{ margin: 0, marginBottom: 0, width: "25%", maxWidth: "300px" }}
				/>				
				<MainButton
					enabled={manualFile.size !== 0}
					onClick={handleOnClickSave}
					text={MESSAGES.PT_BR.MANUAL.SAVE}
					darkColor={COLORS.DEFAULT_COLORS.FIRST_BUTTON}
					lightColor={COLORS.DEFAULT_COLORS.FIRST_BUTTON}
				/>
			</ContentFooter>
		</Content>
	);
};

export default Manual;