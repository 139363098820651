import { FaqFeedbackTopicCommentResponseModel, GroupedFaqFeedbackResponseModel } from "technos-api-frontend-bridge";
import { FaqFeedbackTopicComment, GroupedFaqFeedback } from "../FaqFeedback";

export const mapGroupedFaqFeedbackFromApiToModel = (apiData: GroupedFaqFeedbackResponseModel): GroupedFaqFeedback => {
	return {
		themeId: apiData.theme_id,
		themeName: apiData.theme_name,
		themeOrder: apiData.theme_order,
		items: apiData.items.map((item) => ({
			topicId: item.topic_id,
			topicName: item.topic_name,
			isUsefulTotal: item.is_useful_total,
			isNotUsefulTotal: item.is_not_useful_total,
			problemsTotal: item.problems_total,
			commentsTotal: item.comments_total,
		}))
	};
};

export const mapFaqFeedbackTopicCommentsFromApiToModel = (apiData: FaqFeedbackTopicCommentResponseModel): FaqFeedbackTopicComment => {
	return {
		topicId: apiData.topic_id,
		topicName: apiData.topic_name,
		userName: apiData.user_name,
		userEmail: apiData.user_email,
		createdAt: apiData.created_at,
		problem: apiData.problem,
		comment: apiData.comment,
	};
};