
// Todas as features, incluindo relógios e dispositivos default
export const FEATURES = {
	MOBILE_USERS: "Usuários",
	PUSH_NOTIFICATIONS : "Push Notifications",
	FAQ : "Faq",
	SUPPORT : "E-mail e suporte",
	TERMS_AND_CONDITIONS : "Termos e condições",
	API_KEY : "Chaves de API",
	WATCH_FACES : "Mostradores",
	TUTORIALS : "Tutoriais",
	MANUAL : "Manual do Usuário",
	FIRMWARE : "Firmware",
	GPS: "Gps",
	SLEEP_SCORE : "Score de Sono",
	TRAINING: "Treino",
	SHARE_EXERCISE: "Compartilhar Exercício",
	CUSTOM_EXERCISE: "Exercício Customizado",
	STORE_LINK: "Links das Lojas",

	//Config features
	DEVICES: "Dispositivos",
	ADMIN_USERS: "Admin Users",
	CONFIRMATION_EMAIL: "Email de confirmação",
	RECOVERY_PASSWORD: "Recuperação de senha",
	DELETE_APP_USER: "Deletar usuário do app",
	ADD_ADMIN_USER: "Adicionar usuário do webAdmin",
};

//Features disponíveis apenas para os relógios
export const DEVICE_FEATURES = [
	FEATURES.WATCH_FACES,
	FEATURES.FIRMWARE,
	FEATURES.TUTORIALS,
	FEATURES.MANUAL,
	FEATURES.SLEEP_SCORE,
	FEATURES.CUSTOM_EXERCISE,
	FEATURES.GPS,
];
	
