import styled from "styled-components";

export const ModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	transition: visibility 0s, opacity 0.3s linear;
`;

export const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 28%;
	min-width: 800px;
	height: 500px;
	background-color: ${props => props.theme.backgroundCard};
	border-radius: 24px;
	padding: 25px;
	padding-top: 5px;
	padding-bottom: 0px;
	box-sizing: border-box;
`;

export const ModalHeader = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	h1 {
		font-size: 28px;
		padding: 10px;
		text-align: center;
		font-weight: 600;
		line-height: 30px;
		color: ${props => props.theme.primaryColor};
	}

	div {
		&:hover {
			cursor: pointer;
		}
	}
`;

export const ModalBody = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	width: 100%;
	height: 100%;

	button {
		align-self: flex-end;
		width: 30%;
		margin-top: 0px;
		background-color: ${props => props.theme.primaryColor};
	}
`;

export const ScrollContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	max-height: 400px;
	align-items: center;
	padding-right: 5px;
	
	overflow-y: scroll;
	overflow: auto;
`;
