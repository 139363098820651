import React, { createContext, useContext } from "react";
import { Api } from "../services";
import { ShareExercise } from "../types/ShareExercise";

interface ICustomExerciseContextData {
	getShareExerciseList: (appCode: number) => Promise<ShareExercise[] | null>;
	createNewShareExercise: (newExercise: ShareExercise, imageFile: File, appCode: number) => Promise<void>;
	updateShareExercise: (updatedExercise: ShareExercise, newImageFile: File, appCode: number) => Promise<void>;
	deleteShareExercise: (shareExercise: ShareExercise, appCode: number) => Promise<void>;
	saveNewOrder: (newOrder: ShareExercise[], appCode: number) => Promise<void>,
}

interface CustomExerciseProviderProps {
	children: React.ReactNode;
}

export const ShareExerciseContext = createContext({} as ICustomExerciseContextData);

const ShareExerciseProvider: React.FC<CustomExerciseProviderProps> = ({ children }) => {

	const S3_FOLDER = "shareExercise";

	const getShareExerciseList = (appCode: number) => {
		return new Promise<ShareExercise[] | null>((resolve, reject) => {
			Api.ShareExercise.getShareExercise(appCode)
				.then((customExerciseList) => {
					resolve(customExerciseList);
				}).catch((errorMessage) => {
					reject(errorMessage);
				});
		});
	};

	const createNewShareExercise = (newExercise: ShareExercise, imageFile: File, appCode: number) => {
		let imagePromise: Promise<string>;
		return new Promise<void>((resolve, reject) => {
			imagePromise = Api.AWS.sendOrUpdateFileToS3(appCode, imageFile, S3_FOLDER);
			Promise.all([imagePromise]).then(urls => {
				if (urls[0]) newExercise.image = urls[0];
				Api.ShareExercise.createNewShareExercise(newExercise, appCode)
					.then(() => resolve())
					.catch(apiError => reject(apiError));
			}).catch(s3Error => {
				reject(s3Error);
			});
		});
	};

	const updateShareExercise = (updatedExercise: ShareExercise, newImageFile: File, appCode: number) => {
		let imagePromise: Promise<string>;
		return new Promise<void>((resolve, reject) => {
			if (newImageFile.size) {
				imagePromise = Api.AWS.sendOrUpdateFileToS3(appCode, newImageFile, S3_FOLDER, updatedExercise.image);
			}
			Promise.all([imagePromise]).then(urls => {
				if (urls[0]) updatedExercise.image = urls[0];
				Api.ShareExercise.updateShareExercise(updatedExercise, appCode)
					.then(() => resolve())
					.catch(apiError => reject(apiError));
			}).catch(s3Error => {
				reject(s3Error);
			});
		});
	};

	const deleteShareExercise = (shareExercise: ShareExercise, appCode: number) => {
		return new Promise<void>((resolve, reject) => {
			Api.ShareExercise.deleteShareExercise(shareExercise.id, appCode)
				.then(() => resolve())
				.catch(apiError => reject(apiError));
		});
	};

	const saveNewOrder = async (newOrder: ShareExercise[], appCode: number) => {
		const emptyFile = new File([], "");
		const tasks = new Array(newOrder.length).fill(updateShareExercise);
		for (let i = 0; i < newOrder.length; i++) {
			try{
				await tasks[i](newOrder[i], emptyFile, appCode);
			} catch(e){
				return Promise.reject(e);
			}
		}
		return Promise.resolve();
	};

	return (
		<ShareExerciseContext.Provider
			value={{
				getShareExerciseList,
				createNewShareExercise,
				updateShareExercise,
				deleteShareExercise,
				saveNewOrder
			}}>
			{children}
		</ShareExerciseContext.Provider>
	);
};

const useShareExercise = () => {
	const context = useContext(ShareExerciseContext);

	return context;
};

export { ShareExerciseProvider, useShareExercise };
