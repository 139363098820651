

/**
 * API Object (Response Sample)
 * [
  {
    "theme": "Theme 1",
    "orderTheme": 0,
    "idTheme": 1,
    "topics": [
     {
        "idTopic": 11,
        "topic": "Como parear no iOS",
        "content": "Ligando o bluetooth",
        "tags": [],
        "slugs": [
          "teste",
          "mlife"
        ]
      }
    ]
  }
]
 */

/**
 * App Object
 * 
    export interface FaqArticle {
        theme: string;
        index: number;
        id: string;
        topics: FaqTopic[];
    }

    export interface FaqTopic {
        topicId: string;
        topic: string;
        content: string;
        tags: string[];
        devices;
    }
 */

const fromApiToModel = (faq) => {
	return {
		theme: faq.theme,
		index: faq.orderTheme,
		id: faq.idTheme,
		darkIcon: faq.darkIconS3,
		darkIconCDN: faq.darkIcon,
		lightIcon: faq.lightIconS3,
		lightIconCDN: faq.lightIcon,
		topics: faq?.topics?.map((topic) => {
			return {
				topicId: topic.idTopic,
				topic: topic.topic,
				content: topic.content,
				tags: topic.tags,
				devices: topic.slugs,
			};
		}) ?? [],
	};
};

/**
 * 
 * Api Object for update or create Theme (Article)
 */
const fromFaqArticleModelToApi = (appFaq) => {
	return {
		theme: appFaq.theme,
		darkIcon: appFaq.darkIcon,
		lightIcon: appFaq.lightIcon,
		index: appFaq.index,
	};
};

/**
 * 
 * API theme Object: 
{
  "theme": "string",
  "index": 0
}
 */
const fromAppThemeToApiTheme = (themeName, index, darkIcon, lightIcon) => {
	return {
		theme: themeName,
		darkIcon: darkIcon,
		lightIcon: lightIcon,
		index: index,
	};
};

/**
 * 
{
  "IdTheme": 0,
  "Topic": "string",
  "Content": "string",
  "Tags": [
    "string"
  ],
  "slugs": [
    "string"
  ]
}
 */

const fromAppTopicToApiTopic = (faqTopic, themeId) => {
	return {
		IdTheme: themeId,
		Topic: faqTopic.topic,
		Content: faqTopic.content,
		Tags: faqTopic.tags,
		slugs: faqTopic.devices,
	};
};

/**
 * Update APi Object
 * {
  "IdTheme": 0,
  "Topic": "string",
  "Content": "string",
  "Tags": [
    "string"
  ],
  "slugs": [
    "string"
  ]
}

 */
const fromAppTopicToUpdatedApiObject = (faqTopic, themeId) => {
	return {
		IdTheme: themeId,
		Topic: faqTopic.topic,
		Content: faqTopic.content,
		Tags: faqTopic.tags,
		slugs: faqTopic.devices,
	};
};

export const FaqMapper = {
	fromApiToModel,
	fromFaqArticleModelToApi,
	fromAppThemeToApiTheme,
	fromAppTopicToApiTopic,
	fromAppTopicToUpdatedApiObject
};