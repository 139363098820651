export const FAQ_MESSAGES = {
	THEME_TITLE: "Temas",
	EVALUATION_TITLE: "Avaliação",
	
	NEW_ARTICLE_BTN: "Novo artigo",
	ADD_ARTICLE_MODAL_TITLE: "Adicionar artigo",
	THEME: "Tema",
	THEME_PLACEHOLDER: "Pareamento",
	TOPIC: "Tópico",
	TOPIC_PLACEHOLDER: "Como parear com o iOS",
	CONTENT: "Conteúdo",
	CONTENT_PLACEHOLDER: "Como parear com o iOS",
	TAGS: "Tags",
	ADD_ARTICLE_BTN: "Adicionar",
	ADD_AND_NEW_ARTICLE_BTN: "Adicionar e criar novo artigo",
	CREATE_THEME: "Criar o tema",
	CREATE_TAG: "Criar a tag",
	FEEDBACK_COMMENTS_MODAL_TITME: "Avaliações",

	EDIT_THEME: "Editar tema",
	SAVE_BTN: "Salvar",
	
	DELETE_THEME_TITLE: "Tem certeza que deseja excluir o tema?",
	DELETE_THEME_DESCRIPTION: "Caso você exclua o tema, todos os tópicos e artigos serão excluidos também. Caso não queira perder os artigos mude-o de tema antes de concluir a exclusão. ",

	DELETE_TOPIC_TITLE: "Tem certeza que deseja excluir o tópico?",
	DELETE_TOPIC_DESCRIPTION: "Caso você exclua esse tópico o mesmo não poderá ser resgatado.",
	CANCEL_BTN: "Não tenho certeza, cancelar.",
	CONFIRM_DELETE: "Tenho certeza, excluir.",

	EDIT_ARTICLE: "Editar artigo",
	MANDATORY_FIELD: "Campo obrigatório",
	LOADING: "Carregando...",
	DELETING: "Excluindo...",

	ERROR_UPDATE_THEME: "Erro ao atualizar tema",
	ERROR_UPDATE_THEME_DESCRIPTION: "Ocorreu um erro ao atualizar o tema: ",
	ERROR_UPDATE_TOPIC: "Erro ao atualizar tópico",
	ERROR_UPDATE_TOPIC_DESCRIPTION: "Ocorreu um erro ao atualizar o tópico: ",
	ERROR_ADD_ARTICLE: "Ocorreu um erro ao adicionar o artigo.",
	ERROR_ADD_ARTICLE_DESCRIPTION: "Ocorreu um erro ao adicionar o artigo: ",
	ERROR_EDIT_ARTICLE: "Erro ao editar artigo",
	ERROR_EDIT_ARTICLE_DESCRIPTION: "Ocorreu um erro ao editar o tópico: ",
	ERROR_DELETE_THEME: "Erro ao excluir tema",
	ERROR_DELETE_THEME_DESCRIPTION: "Ocorreu um erro ao excluir o tema: ",
	ERROR_DELETE_TOPIC: "Erro ao excluir tópico",
	ERROR_DELETE_TOPIC_DESCRIPTION: "Ocorreu um erro ao excluir o tópico: ",

	SUCCESS_UPDATE_THEME: "Atualizado com sucesso!",
	SUCCESS_UPDATE_THEME_DESCRIPTION: "O tema foi atualizado com sucesso.",
	SUCCESS_UPDATE_TOPIC: "Atualizado com sucesso!",
	SUCCESS_UPDATE_TOPIC_DESCRIPTION: "O tópico foi atualizado com sucesso.",
	SUCCESS_ADD_ARTICLE: "Adicionado com sucesso!",
	SUCCESS_EDIT_ARTICLE_DESCRIPTION: "O artigo foi editado com sucesso.",
	SUCCESS_ADD_ARTICLE_DESCRIPTION: "O artigo foi adicionado com sucesso.",
	SUCCESS_DELETE_THEME: "Excluído com sucesso!",
	SUCCESS_DELETE_THEME_DESCRIPTION: "O tema foi excluído com sucesso.",
	SUCCESS_DELETE_TOPIC: "Excluído com sucesso!",
	SUCCESS_DELETE_TOPIC_DESCRIPTION: "O tópico foi excluído com sucesso.",

	ADDING_THEME: "Adicionando tema...",
	ADDED_SUCCESSFULLY: "Adicionado com sucesso!",
	ADD_THEME_ERROR: "Erro ao adicionar tema",

	SUCCESS: "Sucesso!",
	ERROR: "Erro!",

	DRAG_DISABLED: "Reordenação bloqueada",
	DRAG_ENABLED: "Reordenação desbloqueada",
};