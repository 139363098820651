import React, { useState } from "react";
import { MESSAGES } from "../../../../../../../../../../constants/messages";
import { useTheme } from "../../../../../../../../../../contexts/theme";
import { ModalBody, ModalContainer, ModalContent, ModalHeader, ImageIcon, BlockIcon, TitleIcon, GridBlockIcon } from "./styles";
import { ReactComponent as CloseIcon } from "../../../../../../../../../../assets/images/closeIcon.svg";
import { ImageInputWithPreview, MainInput } from "../../../../../../../../../../components/Inputs";
import { FaqArticle } from "../../../../../../../../../../types/FaqArticle";
import { MainButton } from "../../../../../../../../../../components/Buttons";
import { toast } from "react-hot-toast";
import { useFaq } from "../../../../../../../../../../contexts/faq";
import { useMobileApp } from "../../../../../../../../../../contexts/mobileApp";

interface ModalProps {
	onClose: (statusFeedback) => void;
	faq: FaqArticle
}

const EditThemeModal = (props: ModalProps) => {

	const { currentTheme } = useTheme();
	const { updateFaqTheme } = useFaq();
	const { selectedApp } = useMobileApp();
	const [faq, setFaq] = useState<FaqArticle>(props.faq);
	const [lightIcon, setLightIcon] = useState<string>(props.faq.lightIconCDN);
	const [darkIcon, setDarkIcon] = useState<string>(props.faq.darkIconCDN);
	const [lightIconFile, setLightIconFile] = useState<File>(new File([], ""));
	const [darkIconFile, setDarkIconFile] = useState<File>(new File([], ""));
	const [themeError, setThemeError] = useState<string>("");

	const handleEditTheme = () => {
		if (faq?.theme?.length === 0) {
			setThemeError(MESSAGES.PT_BR.FAQ_MESSAGES.MANDATORY_FIELD);
			return;
		} else {
			setThemeError("");
		}

		const t = toast.loading(MESSAGES.PT_BR.FAQ_MESSAGES.LOADING);
		updateFaqTheme(selectedApp.appCode, faq, lightIconFile, darkIconFile)
			.then(() => {
				props.onClose({ isOpen: true, title: MESSAGES.PT_BR.FAQ_MESSAGES.SUCCESS_UPDATE_THEME, description: MESSAGES.PT_BR.FAQ_MESSAGES.SUCCESS_UPDATE_THEME_DESCRIPTION, success: true });
			}).catch((error) => {
				props.onClose({ isOpen: true, title: MESSAGES.PT_BR.FAQ_MESSAGES.ERROR_UPDATE_THEME, description: MESSAGES.PT_BR.FAQ_MESSAGES.ERROR_UPDATE_THEME_DESCRIPTION + error, success: false });
			}).finally(() => {
				toast.dismiss(t);
			});
	};

	const onSelectIcon = (file: File, type: "dark" | "light") => {
		const createImgFile = URL.createObjectURL(file);

		if (type === "light") {
			setLightIcon(createImgFile);
			setLightIconFile(file);
		} else {
			setDarkIcon(createImgFile);
			setDarkIconFile(file);
		}
	};

	return (
		<ModalContainer>
			<ModalContent>
				<ModalHeader>
					<h1>{MESSAGES.PT_BR.FAQ_MESSAGES.EDIT_THEME}</h1>
					<div>
						<CloseIcon onClick={() => props.onClose(null)} />
					</div>
				</ModalHeader>
				<ModalBody>

					<GridBlockIcon>
						<BlockIcon>
							<TitleIcon>Light</TitleIcon>
							<ImageInputWithPreview
								onSelectImage={(e) => onSelectIcon(e, "light")}
								image={lightIcon}
								style={{ minWidth:"150px", width:"150px", height:"150px", borderRadius:"12px"}}
								editBtnStyle={ {width:"45px", height:"45px", bottom: "-14px", right: "-10px", background: currentTheme.backgroundCardSurface }}
								withCssImage={!!lightIcon}
								defaultImageComponent={<ImageIcon />}
							/>
						</BlockIcon>

						<BlockIcon>
							<TitleIcon>Dark</TitleIcon>
							<ImageInputWithPreview
								onSelectImage={(e) => onSelectIcon(e, "dark")}
								image={darkIcon}
								style={{ minWidth:"150px", width:"150px", height:"150px", borderRadius:"12px"}}
								editBtnStyle={ {width:"45px", height:"45px", bottom: "-14px", right: "-10px", background: currentTheme.backgroundCardSurface }}
								withCssImage={!!darkIcon}
								defaultImageComponent={<ImageIcon />}
							/>
						</BlockIcon>
					</GridBlockIcon>
					
					<MainInput
						type="text"
						placeholder=""
						value={faq?.theme ?? ""}
						errorText={themeError}
						onChange={(value) => { setFaq({ ...faq, theme: value }); }}
					/>
					<MainButton enabled onClick={handleEditTheme} text={MESSAGES.PT_BR.FAQ_MESSAGES.SAVE_BTN} />
				</ModalBody>
			</ModalContent>
		</ModalContainer>
	);

};

export default EditThemeModal;