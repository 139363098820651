import React, { createContext, useContext } from "react";
import { Api } from "../services";
import { StoreLink } from "../types/StoreLink";

interface IStoreLinkContextData {
	getStoreLink: (appCode: number) => Promise<StoreLink>;
	updateStoreLink: (androidLink: string, iosLink: string, appCode: number) => Promise<void>;
	getRedirectLink: (appCode: number) => string;
}

interface StoreLinkProviderProps {
	children: React.ReactNode;
}

export const StoreLinkContext = createContext({} as IStoreLinkContextData);

const StoreLinkProvider: React.FC<StoreLinkProviderProps> = ({ children }) => {

	const getStoreLink = (appCode: number) => {
		return new Promise<StoreLink>((resolve, reject) => {
			Api.StoreLink.getStoreLink(appCode)
				.then((links) => resolve(links))
				.catch((err) => reject(err));
		});
	};

	const updateStoreLink = (androidLink: string, iosLink: string, appCode: number) => {
		return new Promise<void>((resolve, reject) => {
			Api.StoreLink.updateStoreLink(androidLink, iosLink, appCode)
				.then(() => resolve())
				.catch((err) => reject(err));
		});
	};

	const getRedirectLink = (appCode: number) => {
		return Api.StoreLink.getRedirectLink(appCode);
	};

	return (
		<StoreLinkContext.Provider
			value={{
				getStoreLink,
				updateStoreLink,
				getRedirectLink
			}}>
			{children}
		</StoreLinkContext.Provider>
	);
};

const useStoreLink = () => {
	const context = useContext(StoreLinkContext);

	return context;
};

export { StoreLinkProvider, useStoreLink };
