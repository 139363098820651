import { FC, SVGProps } from "react";

export type SvgContainerProps = SVGProps<SVGSVGElement> & {
  SvgComponent: FC<SVGProps<SVGSVGElement>>;
}

const SvgContainer = ({ SvgComponent, ...props }: SvgContainerProps) => {
	return <SvgComponent {...props} />;
};

export default SvgContainer;