import styled from "styled-components";

export const StackContainer = styled.div`
    display: flex;
    position: relative;
    width: 240px;
    min-width: 240px;

    div {
        &:hover {
            cursor: pointer;
        }
    }
`;

export const Container = styled.div`
    display: flex;
    width: 100%;
    height: 50px;
    border: 2px solid ${props => props.theme.secondaryColor};
    border-radius: 16px;
    align-items: center;
`;

export const Spacing = styled.div`
    position: absolute;
    margin-top: 65px;
    z-index: 999;
    min-width: 240px;
`;

export const ItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    background-color: ${props => props.theme.backgroundCard};
    max-height: 250px;
    overflow-y: scroll;
`; 

export const DropdownItem = styled.div`
    display: flex;
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 5px;
    justify-content: space-between;

    &:hover {
        background-color: ${props => props.theme.backgroundCardSurface};
    }
`;

export const ItemContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const SaveContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 8px;

    button {
        width: 140px;
    }
`;

export const Image = styled.img`
    width: 38px;
    height: 38px;
    border-radius: 8px;
    margin-left: 5px;
    margin-right: 7px;
`;

export const SelectedCountContainer = styled.div`
    display: flex;
    position: relative;
`;

export const SelectedCount = styled.text`
    position: absolute;
    bottom: 0;
    right: 0;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    color: ${props => props.theme.secondaryTextColor};
    background-color: ${props => props.theme.secondaryColor};
    size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Text = styled.text`
    color: ${props => props.theme.secondaryColor};
`;

export const OneLineText = styled.text`
    color: ${props => props.theme.secondaryColor};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
`;

export const Input = styled.input.attrs(() => ({
	type: "checkbox" ,
	margin: "15px",
	width: "20px",
}))`
    accent-color: ${props => props.theme.primaryColor};
    margin-left: ${props => props.margin};
    width: ${props => props.width};
    min-width: ${props => props.width};
`;