import React, { useState } from "react";
import {
	StackContainer,
	ExpandedContainer,
	ImgContainer,
	HeaderContainer,
	TextContainer,
	ArrowImageContainer,
	TopicInfoTable,
} from "./styles";
import { useTheme } from "../../../../../../../contexts/theme";


import { GroupedFaqFeedback } from "../../../../../../../types/FaqFeedback";
import FeedbackComments from "./FeedbackComments";

interface Props {
	groupedFaqFeedback: GroupedFaqFeedback;
}

const CollapsableFaqFeedbackData = ({ groupedFaqFeedback }: Props) => {
	const { currentTheme } = useTheme();

	const [isExpanded, setIsExpanded] = useState(false);
	const [modalTopicId, setModalTopicId] = useState<number | null>(null);

	return (
		<StackContainer theme={currentTheme}>
			<HeaderContainer onClick={() => setIsExpanded(x => !x)}>
				<TextContainer theme={currentTheme}>
					<h2>{groupedFaqFeedback.themeName}</h2>
				</TextContainer>

				<ImgContainer>
					<ArrowImageContainer
						isEmpty={groupedFaqFeedback.items.length === 0}
						appTheme={currentTheme}
						isExpanded={isExpanded}
					/>
				</ImgContainer>

			</HeaderContainer>
			
			{isExpanded &&
				<ExpandedContainer>
					<TopicInfoTable appTheme={currentTheme}>
						<thead>
							<td>Tópico</td>
							<td>Útil</td>
							<td>Não Útil</td>
							<td>O que deu errado</td>
							<td></td>
						</thead>
						<tbody>
							{groupedFaqFeedback.items.map((item) => (
								<tr key={item.topicId}>
									<td>{item.topicName}</td>
									<td>{item.isUsefulTotal}</td>
									<td>{item.isNotUsefulTotal}</td>
									<td>{item.commentsTotal}</td>
									<td>
										<button onClick={() => setModalTopicId(item.topicId)}>Comentários</button>
									</td>
								</tr>
							))}
						</tbody>
					</TopicInfoTable>
				</ExpandedContainer>
			}

			<FeedbackComments modalTopicId={modalTopicId} closeCallback={() => setModalTopicId(null)} />
		</StackContainer>
	);
};

export default CollapsableFaqFeedbackData;