import { Device } from "../../types/Device";
import { FEATURES } from "../features";

export const TRANSITION_TIME = "0.5s";

export const DEFAULT_DEVICE = {
	name: "Dispositivo",
	slug: "device",
	image: "https://media.istockphoto.com/id/1020715540/pt/vetorial/smartwatch-icon-vector-sign-and-symbol-isolated-on-white-background-smartwatch-logo-concept.jpg?s=170667a&w=0&k=20&c=p7suy-lXCSQ2x19rm34WWzkP6Vhfp7xi1cAPcLYz_-c=",

	availableFeatures: [
		FEATURES.MOBILE_USERS,
		FEATURES.PUSH_NOTIFICATIONS,
		FEATURES.FAQ,
		FEATURES.SUPPORT,
		FEATURES.TERMS_AND_CONDITIONS,
		FEATURES.API_KEY,
		FEATURES.TRAINING,
		FEATURES.SHARE_EXERCISE,
		FEATURES.STORE_LINK,
	]
} as Device;