//Treino
export interface WorkoutSection {
    id: number;
    name: string;
    exerciseType: number;
    icon: string;
    iconCDN: string;
    iconSelected: string;
    iconSelectedCDN: string;
    index: number;
    trainingVideos: WorkoutVideo[];
}

//Uma aula daquele treino
export interface WorkoutVideo {
    id: number;
    difficulty: WorkoutLevel;
    episode: number;
    secondsDuration?: number;
    description: string;
    link: string;
    thumbnail: string;
    thumbnailCDN: string;
    available: boolean;
    slugs: string[];
    trainingVideoSectionId: number;
}

export enum WorkoutLevel {
    BEGINNER = 1,
    INTERMEDIATE = 2,
    ADVANCED = 3
}
