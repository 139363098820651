import axios from "axios";
import { getApi } from ".";
import { FaqFeedbackTopicCommentResponseModel, GroupedFaqFeedbackResponseModel } from "technos-api-frontend-bridge";
import { mapFaqFeedbackTopicCommentsFromApiToModel, mapGroupedFaqFeedbackFromApiToModel } from "../../types/Mapper/FaqFeedbackMapper";

export const getGroupedFaqFeedback = async (appCode: number) => {
	try {
		const { data: groupedFaqFeedbackResponse } = await getApi(appCode).get<GroupedFaqFeedbackResponseModel[]>("/feedback/faq");
		return groupedFaqFeedbackResponse.map(mapGroupedFaqFeedbackFromApiToModel);
	} catch (error) {
		console.log(error);
		if (axios.isAxiosError(error))
			throw new Error(error.response?.data.title);
		throw error;
	}
};

export const getFaqFeedbackTopicComments = async (appCode: number, topicId: number) => {
	try {
		const { data: groupedFaqFeedbackResponse } = await getApi(appCode)
			.get<FaqFeedbackTopicCommentResponseModel[]>("/feedback/faq/topic", {
				params: { topic_id: topicId }
			});
		return groupedFaqFeedbackResponse.map(mapFaqFeedbackTopicCommentsFromApiToModel);
	} catch (error) {
		console.log(error);
		if (axios.isAxiosError(error))
			throw new Error(error.response?.data.title);
		throw error;
	}
};