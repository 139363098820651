import React, { useState } from "react";
import { MdOutlineWatch } from "react-icons/md";
import { ReactComponent as CloseIcon } from "../../../assets/images/closeIcon.svg";
import { useTheme } from "../../../contexts/theme";
import { MESSAGES } from "../../../constants/messages";
import { MainInput, ImageInputWithPreview, MainCheckBox } from "../../Inputs";
import { MainButton } from "../../Buttons";
import { CheckBoxGroup, ModalBody, ModalContainer, ModalContent, ModalHeader } from "./styles";
import { Device, FirmwareType, GpsType, WatchFaceType } from "../../../types/Device";
import { useDevice } from "../../../contexts/device";
import { useMobileApp } from "../../../contexts/mobileApp";
import { DEVICE_FEATURES, FEATURES } from "../../../constants/features";
import { OptionBaseProps } from "../../Inputs/CheckBox";

interface ModalProps {
	currentDevice: Device;
	onCancel: () => void;
	onClose: (feedback) => void;
}

const EditDeviceModal = (props: ModalProps) => {

	const { currentTheme } = useTheme();
	const { updateDevice } = useDevice();
	const { selectedApp } = useMobileApp();

	const [updatedDevice, setUpdatedDevice] = useState<Device>(props.currentDevice);
	const [imageFile, setImageFile] = useState<File>(new File([], ""));
	const [deviceNameError, setDeviceNameError] = useState<string>("");

	const handleUpdateDevice = () => {
		let allRight = true;

		if (updatedDevice?.name === undefined || updatedDevice?.name?.length === 0) {
			setDeviceNameError(MESSAGES.PT_BR.DEVICE_MODAL.MANDATORY_FIELD);
			allRight = false;
		} else {
			setDeviceNameError("");
		}

		if (allRight) {
			let feedback = {};
			updateDevice(updatedDevice, imageFile, selectedApp.appCode, "deviceImage").then(() => {
				feedback = {
					isOpen: true,
					title: MESSAGES.PT_BR.DEVICE_MODAL.EDIT_DEVICE_FEEDBACK_TITLE,
					description: MESSAGES.PT_BR.DEVICE_MODAL.EDIT_DEVICE_FEEDBACK_DESCRIPTION,
					success: true
				};
			}).catch((errorMessage) => {
				feedback = {
					isOpen: true,
					title: MESSAGES.PT_BR.DEVICE_MODAL.EDIT_DEVICE_FEEDBACK_TITLE_ERROR,
					description: MESSAGES.PT_BR.DEVICE_MODAL.EDIT_DEVICE_FEEDBACK_TITLE_ERROR + errorMessage,
					success: false
				};
			}).finally(() => {
				props.onClose(feedback);
			});
		}
	};

	const getSubOptions = (feature: string) => {
		const subOptions:OptionBaseProps[] = [];
		if(updatedDevice?.availableFeatures?.includes(feature)){
			switch (feature) {
			case FEATURES.WATCH_FACES:
				subOptions.push(
					{
						label: MESSAGES.PT_BR.DEVICE_MODAL.TYPE_ZIP,
						checked: updatedDevice.watchFaceType === WatchFaceType.ZIP,
						onChange: () => setUpdatedDevice({ ...updatedDevice, watchFaceType: WatchFaceType.ZIP })
					},
					{
						label: MESSAGES.PT_BR.DEVICE_MODAL.TYPE_BIN,
						checked: updatedDevice.watchFaceType === WatchFaceType.BIN,
						onChange: () => setUpdatedDevice({ ...updatedDevice, watchFaceType: WatchFaceType.BIN })
					},
					{
						label: MESSAGES.PT_BR.DEVICE_MODAL.TYPE_WATCH,
						checked: updatedDevice.watchFaceType === WatchFaceType.WATCH,
						onChange: () => setUpdatedDevice({ ...updatedDevice, watchFaceType: WatchFaceType.WATCH })
					},
				);
				break;
			case FEATURES.FIRMWARE:
				subOptions.push(
					{
						label: MESSAGES.PT_BR.DEVICE_MODAL.SEQUENTIAL,
						checked: updatedDevice.firmwareType === FirmwareType.SEQUENTIAL,
						onChange: () => setUpdatedDevice({ ...updatedDevice, firmwareType: FirmwareType.SEQUENTIAL })
					},
					{
						label: MESSAGES.PT_BR.DEVICE_MODAL.TYPE_ZIP,
						checked: updatedDevice.firmwareType === FirmwareType.ZIP,
						onChange: () => setUpdatedDevice({ ...updatedDevice, firmwareType: FirmwareType.ZIP })
					},
					{
						label: MESSAGES.PT_BR.DEVICE_MODAL.TYPE_BIN,
						checked: updatedDevice.firmwareType === FirmwareType.BIN,
						onChange: () => setUpdatedDevice({ ...updatedDevice, firmwareType: FirmwareType.BIN })
					});
				break;
			case FEATURES.GPS:
				subOptions.push(
					{
						label: MESSAGES.PT_BR.DEVICE_MODAL.TYPE_AGPS,
						checked: updatedDevice.gpsType === GpsType.AGPS,
						onChange: () => setUpdatedDevice({ ...updatedDevice, gpsType: GpsType.AGPS })
					},
					{
						label: MESSAGES.PT_BR.DEVICE_MODAL.TYPE_EPO,
						checked: updatedDevice.gpsType === GpsType.EPO,
						onChange: () => setUpdatedDevice({ ...updatedDevice, gpsType: GpsType.EPO })
					},
				);	
			}
		}
		return subOptions;
	};

	return (
		<ModalContainer>
			<ModalContent>
				<ModalHeader>
					<h1>{MESSAGES.PT_BR.DEVICE_MODAL.EDIT_DEVICE}</h1>
					<div>
						<CloseIcon onClick={props.onCancel}/>
					</div>
				</ModalHeader>
				<ModalBody>
					<ImageInputWithPreview
						onSelectImage={setImageFile}
						image={imageFile.size? URL.createObjectURL(imageFile) : props.currentDevice.imageCDN}
						defaultImageComponent={<MdOutlineWatch color={currentTheme.textColor} size={"60%"} />}
					/>
					<MainInput
						type="text"
						title={MESSAGES.PT_BR.DEVICE_MODAL.DEVICE_NAME}
						placeholder={MESSAGES.PT_BR.DEVICE_MODAL.DEVICE_NAME_PLACEHOLDER}
						value={updatedDevice?.name || ""}
						errorText={deviceNameError}
						onChange={deviceName => setUpdatedDevice({ ...updatedDevice, name: deviceName })}
					/>
					<MainInput
						type="text"
						title={MESSAGES.PT_BR.DEVICE_MODAL.DEVICE_SLUG}
						placeholder={MESSAGES.PT_BR.DEVICE_MODAL.DEVICE_SLUG_PLACEHOLDER}
						value={updatedDevice?.slug || ""}
						onChange={() => null}
						disabled
					/>
					<h1>{MESSAGES.PT_BR.DEVICE_MODAL.FUNCTIONS}</h1>
					<CheckBoxGroup>
						{
							DEVICE_FEATURES.map((currentFeature, index) => (
								<MainCheckBox
									key={index}
									label={currentFeature}
									checked={updatedDevice?.availableFeatures?.includes(currentFeature)}
									onChange={checked => {
										if (!updatedDevice?.availableFeatures?.includes(currentFeature) && checked) {
											setUpdatedDevice({ ...updatedDevice, availableFeatures: [...updatedDevice?.availableFeatures || [], currentFeature] });
										} else if (updatedDevice?.availableFeatures?.includes(currentFeature) && !checked) {
											setUpdatedDevice({ ...updatedDevice, availableFeatures: updatedDevice?.availableFeatures.filter((feature) => feature !== currentFeature) });
										}
									}}
									subOptions={getSubOptions(currentFeature)}
								/>
							))
						}
					</CheckBoxGroup>
					<MainButton enabled onClick={handleUpdateDevice} text={MESSAGES.PT_BR.DEVICE_MODAL.SAVE} />
				</ModalBody>
			</ModalContent>
		</ModalContainer>
	);
};

export default EditDeviceModal;