import React, { useState } from "react";
import { BiUserCircle } from "react-icons/bi";
import { MESSAGES } from "../../../../../../constants/messages";
import { useTheme } from "../../../../../../contexts/theme";
import { ReactComponent as CloseIcon } from "../../../../../../assets/images/closeIcon.svg";
import { MainInput, ImageInputWithPreview } from "../../../../../../components/Inputs";
import { MainButton } from "../../../../../../components/Buttons";
import { AdminUser } from "../../../../../../types/AdminUser";
import { ModalBody, ModalContainer, ModalContent, ModalFooter, ModalHeader, InfoContent } from "./styles";
import { Auth } from "../../../../../../services";

interface Props {
	user: AdminUser;
	onEditProfileRequested: (newName: string, newPhotoFile: File) => void;
	onEditPassword: () => void;
	onCancel: () => void;
}

const EditProfileModal = (props: Props) => {

	const { currentTheme } = useTheme();

	const [name, setName] = useState<string>(props.user.name);
	const [photo, setPhoto] = useState<string>(props.user.photo?? "");
	const [photoFile, setPhotoFile] = useState<File>(new File([], ""));
	const [nameError, setNameError] = useState<string>("");

	const onSelectImage = (file: File) => {
		// const renamedFile = new File([file], `profile${props.user.id}.jpg`, { type: file.type });
		setPhoto(URL.createObjectURL(file));
		setPhotoFile(file);
	};

	const onSaveProfile = () => {
		if (name.length > 0) {
			setNameError("");
		} else {
			setNameError(MESSAGES.PT_BR.USER_MESSAGES.REQUIRED_FIELD);
			return;
		}
		
		props.onEditProfileRequested(name, photoFile);
	};

	return (
		<ModalContainer>
			<ModalContent>
				<ModalHeader>
					<h1 onClick={() => {navigator.clipboard.writeText(Auth.getToken() || "");}}>{MESSAGES.PT_BR.USER_MESSAGES.EDIT_PROFILE_MODAL_TITLE}</h1>
					<div>
						<CloseIcon onClick={props.onCancel} />
					</div>
				</ModalHeader>
				<ModalBody>
					<ImageInputWithPreview
						onSelectImage={(e) => onSelectImage(e)}
						image={photo}
						style={{ minWidth:"150px", width:"150px", height:"150px", borderRadius:"100px", alignSelf:"flex-start" }}
						imgStyle={{ borderRadius:"100px" }}
						editBtnStyle={ {width:"45px", height:"45px", bottom: "0px", right: "0px" }}
						defaultImageComponent={<BiUserCircle color={currentTheme.textColor} size={"100%"} />}
					/>
					<InfoContent>
						<MainInput
							type="text"
							title={MESSAGES.PT_BR.USER_MESSAGES.NAME}
							value={name}
							placeholder={MESSAGES.PT_BR.USER_MESSAGES.NAME_PLACEHOLDER}
							onChange={setName}
							errorText={nameError}
							style={{ marginBottom: "10px" }}
						/>
						<MainInput
							type="text"
							title={MESSAGES.PT_BR.USER_MESSAGES.EMAIL}
							value={props.user.email}
							placeholder={""}
							onChange={() => null}
							style={{ marginBottom: "10px" }}
							disabled
						/>
						<MainInput
							type="text"
							title={MESSAGES.PT_BR.USER_MESSAGES.ROLE}
							value={props.user.role}
							placeholder={""}
							onChange={() => null}
							style={{ marginBottom: "10px" }}
							disabled
						/>
					</InfoContent>
				</ModalBody>
				<ModalFooter>
					<p onClick={() => props.onEditPassword()}>{MESSAGES.PT_BR.USER_MESSAGES.CHANGE_PASSWORD_BTN}</p>
					<MainButton enabled onClick={() => onSaveProfile()} text={MESSAGES.PT_BR.USER_MESSAGES.SAVE_BTN} />
				</ModalFooter>
			</ModalContent>
		</ModalContainer>
	);
};

export default EditProfileModal;
