import React, { useEffect, useState } from "react";
import { MESSAGES } from "../../../../../../../../constants/messages";
import { useTheme } from "../../../../../../../../contexts/theme";
import { GroupContainer, ModalBody, ModalContainer, ModalContent, ModalFooter, ModalHeader, RowContainer } from "./styles";
import { ReactComponent as CloseIcon } from "../../../../../../../../assets/images/closeIcon.svg";
import { InputWithSuggestion, MainInput } from "../../../../../../../../components/Inputs";
import { MainButton } from "../../../../../../../../components/Buttons";
import { HorizontalDeviceSelection } from "../../../../../../../../components";
import { useDevice } from "../../../../../../../../contexts/device";
import { FaqTopic, IAvailableTheme } from "../../../../../../../../types/FaqArticle";
import { useFaq } from "../../../../../../../../contexts/faq";
import { toast } from "react-hot-toast";
import { useMobileApp } from "../../../../../../../../contexts/mobileApp";
import { Helpers } from "../../../../../../../../helpers";

interface Props {
	isOpen: boolean;
	onClose: (statusFeedback) => void;
}

const AddArticleModal = (props: Props) => {

	const { currentTheme } = useTheme();
	const { deviceListWithoutDefault } = useDevice();
	const { selectedApp } = useMobileApp();
	const { addFaqTheme, addFaqTopic, faqList, availableTags, addLocalTag } = useFaq();

	const [newTopic, setNewTopic] = useState<FaqTopic>({ devices: deviceListWithoutDefault.map((device) => device.slug) } as FaqTopic);

	const [topicError, setTopicError] = useState("");
	const [contentError, setContentError] = useState("");
	const [themeError, setThemeError] = useState("");
	const [tagError, setTagError] = useState("");

	const [selectedTheme, setSelectedTheme] = useState({ id: -1, name: "" });
	const [availableTheme, setAvailableTheme] = useState<IAvailableTheme[]>(Helpers.Faq.getAvailableThemes(faqList));

	useEffect(() => {
		resetStates();
	}, [faqList]);

	const handleAddTag = (newTag: string[]) => {
		if (newTag.length > 0) {
			addLocalTag(newTag[newTag.length - 1]);
		}

		setNewTopic({ ...newTopic, tags: newTag });
	};

	const handleAddTheme = (newTheme: string) => {
		setSelectedTheme({ id: availableTheme.find((theme) => theme.name === newTheme)?.id ?? -1, name: newTheme });
	};

	const handleCreateOption = (value: string) => {
		setAvailableTheme([...availableTheme, { id: availableTheme.length + 1, name: value }]);
		handleAddTheme(value);
	};

	const handleAddTopic = async (keepAdding: boolean) => {
		let allRight = true;
		if (newTopic.topic === undefined || newTopic.topic === "") {
			setTopicError(MESSAGES.PT_BR.FAQ_MESSAGES.MANDATORY_FIELD);
			allRight = false;
		} else {
			setTopicError("");
		}

		if (newTopic.content === undefined || newTopic.content === "") {
			setContentError(MESSAGES.PT_BR.FAQ_MESSAGES.MANDATORY_FIELD);
			allRight = false;
		} else {
			setContentError("");
		}

		if (selectedTheme.name === undefined || selectedTheme.name === "") {
			setThemeError(MESSAGES.PT_BR.FAQ_MESSAGES.MANDATORY_FIELD);
			allRight = false;
		} else {
			setThemeError("");
		}

		if (newTopic.tags === undefined || newTopic.tags.length === 0) {
			setTagError(MESSAGES.PT_BR.FAQ_MESSAGES.MANDATORY_FIELD);
			allRight = false;
		} else {
			setTagError("");
		}

		if (allRight) {
			const t = toast.loading(MESSAGES.PT_BR.FAQ_MESSAGES.LOADING);
			let currentThemeId = selectedTheme.id;
			if (currentThemeId === -1 && selectedTheme.name !== "") {
				try {
					currentThemeId = await addFaqTheme(selectedApp.appCode ?? 0, selectedTheme.name, Helpers.Faq.getNextValidIndex(faqList));
					toast.success(MESSAGES.PT_BR.FAQ_MESSAGES.ADDED_SUCCESSFULLY);
				} catch (err) {
					toast.dismiss(t);
					toast.error(MESSAGES.PT_BR.FAQ_MESSAGES.ADD_THEME_ERROR);
					return;
				}
			}

			try {
				await addFaqTopic(selectedApp.appCode ?? 0, newTopic, currentThemeId);

				if (keepAdding) {
					toast.success(MESSAGES.PT_BR.FAQ_MESSAGES.ADDED_SUCCESSFULLY);
					setNewTopic({
						...newTopic,
						topic: "",
						content: "",
					});
				} else {
					props.onClose({ isOpen: true, title: MESSAGES.PT_BR.FAQ_MESSAGES.ADDED_SUCCESSFULLY, description: MESSAGES.PT_BR.FAQ_MESSAGES.SUCCESS_ADD_ARTICLE_DESCRIPTION, success: true });
				}
			} catch (error) {
				props.onClose({ isOpen: true, title: MESSAGES.PT_BR.FAQ_MESSAGES.ERROR_ADD_ARTICLE, description: MESSAGES.PT_BR.FAQ_MESSAGES.ERROR_ADD_ARTICLE_DESCRIPTION + error, success: false });
			}finally{
				toast.dismiss(t);
			}
		}
	};

	const resetStates = () => {
		setNewTopic({ devices: deviceListWithoutDefault.map((device) => device.slug) } as FaqTopic);
		setSelectedTheme({ id: -1, name: "" });
		setAvailableTheme(Helpers.Faq.getAvailableThemes(faqList));
		setTopicError("");
		setContentError("");
		setThemeError("");
		setTagError("");
	};

	if (props.isOpen) {
		return (
			<ModalContainer theme={currentTheme}>
				<ModalContent theme={currentTheme}>
					<ModalHeader theme={currentTheme}>
						<h1>{MESSAGES.PT_BR.FAQ_MESSAGES.ADD_ARTICLE_MODAL_TITLE}</h1>
						<div>
							<CloseIcon onClick={() => {
								resetStates();
								props.onClose(null);
							}} />
						</div>
					</ModalHeader>
					<ModalBody>
						<RowContainer>
							<InputWithSuggestion
								title={MESSAGES.PT_BR.FAQ_MESSAGES.THEME}
								placeholder={MESSAGES.PT_BR.FAQ_MESSAGES.THEME_PLACEHOLDER}
								createItemText={MESSAGES.PT_BR.FAQ_MESSAGES.CREATE_THEME}
								onAddItemRequested={(newTheme) => { handleAddTheme(newTheme[0]); }}
								onCreateOption={handleCreateOption}
								values={selectedTheme.name}
								availableValues={availableTheme.map(theme => theme.name)}
								errorText={themeError}
							/>
							<h5></h5>
							<MainInput
								type="text"
								title={MESSAGES.PT_BR.FAQ_MESSAGES.TOPIC}
								value={newTopic.topic ?? ""}
								placeholder={MESSAGES.PT_BR.FAQ_MESSAGES.TOPIC_PLACEHOLDER}
								onChange={(value) => { setNewTopic({ ...newTopic, topic: value }); }}
								errorText={topicError}
								inputStyle={{ borderRadius: "16px" }}
							/>
						</RowContainer>
						<MainInput
							type="text"
							title={MESSAGES.PT_BR.FAQ_MESSAGES.CONTENT}
							value={newTopic.content ?? ""}
							placeholder={MESSAGES.PT_BR.FAQ_MESSAGES.CONTENT_PLACEHOLDER}
							onChange={(value) => { setNewTopic({ ...newTopic, content: value }); }}
							rowsNumber={3}
							errorText={contentError}
						/>
						<InputWithSuggestion
							isMulti
							title={MESSAGES.PT_BR.FAQ_MESSAGES.TAGS}
							createItemText={MESSAGES.PT_BR.FAQ_MESSAGES.CREATE_TAG}
							onAddItemRequested={(newTag) => { handleAddTag(newTag); }}
							availableValues={availableTags}
							placeholder={MESSAGES.PT_BR.FAQ_MESSAGES.CONTENT_PLACEHOLDER}
							errorText={tagError}
						/>
						<ModalFooter theme={currentTheme}>
							<HorizontalDeviceSelection
								deviceList={deviceListWithoutDefault}
								selectedDeviceSlugList={newTopic.devices ?? []}
								onSelectionChange={(selectedDevices) => { setNewTopic({ ...newTopic, devices: selectedDevices }); }}
							/>
							<GroupContainer>
								<p onClick={() => handleAddTopic(true)}>{MESSAGES.PT_BR.FAQ_MESSAGES.ADD_AND_NEW_ARTICLE_BTN}</p>
								<MainButton enabled onClick={() => { handleAddTopic(false); }} text={MESSAGES.PT_BR.FAQ_MESSAGES.ADD_ARTICLE_BTN} />
							</GroupContainer>
						</ModalFooter>
					</ModalBody>
				</ModalContent>
			</ModalContainer>
		);
	}

	return null;
};

export default AddArticleModal;
