import styled, { keyframes } from "styled-components";
import { Theme } from "../../../../../../../../types/Theme";

interface ModalProps {
    theme: Theme,
}

const opacity = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

export const ModalContainer = styled.div<ModalProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
`;

interface ContentProps {
    theme: Theme,
}

export const ModalContent = styled.div<ContentProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    max-width: 1000px;
    height: 70%;
    max-height: 600px;
    min-width: 500px;
    background-color: ${props => props.theme.backgroundCard};
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
    animation: ${opacity} 0.3s linear;
`;

export const ModalHeader = styled.div<ContentProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;

    h1 {
        font-size: 28px;
        padding: 10px;
        text-align: center;
        font-weight: 500;
        line-height: 30px;
        color: ${props => props.theme.primaryColor};
    }

    div {
        &:hover {
            cursor: pointer;
        }
    }
`;

export const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
`;

export const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    h5 {
        margin: 1%;
    }
`;

export const ModalFooter = styled.div<ContentProps>`
    display: flex;
    flex-direction: column;
    width: 100%;

    button {
        width: 30%;
        margin-top: 0;
        margin-left: 20px;
        border-radius: 15px;
        background-color: ${props => props.theme.primaryColor};
    }

    p {
        text-decoration: underline;
        color: ${props => props.theme.textColor};

        &:hover {
            cursor: pointer;
        }
    }
`;

export const GroupContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: flex-end;
    width: 40%;
    justify-content: flex-end;

    p {
        white-space: nowrap;
    }
`;


