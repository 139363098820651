import moment from "moment";
import { useTheme } from "../../../../../../../../../contexts/theme";
import { FaqFeedbackTopicComment } from "../../../../../../../../../types/FaqFeedback";
import { Container, Content, Footer, Header } from "./styles";

interface Props {
  faqFeedbackTopicComment: FaqFeedbackTopicComment;
}

const CommentCard = ({ faqFeedbackTopicComment }: Props) => {
	const { currentTheme } = useTheme();

	const { problem, comment, userName, userEmail, createdAt } = faqFeedbackTopicComment;

	return (
		<Container appTheme={currentTheme}>
			<Header appTheme={currentTheme}>
				<div>Usuário: {userName} - {moment(createdAt).format("DD/MM/YYYY")}</div>
				<div>O que deu errado: {problem}</div>
			</Header>
			<Content appTheme={currentTheme}>
				{comment || "-"}
			</Content>
			<Footer appTheme={currentTheme}>
        Email: {userEmail}
			</Footer>
		</Container>
	);
};

export default CommentCard;