import React from "react";
import {
	Routes,
	Route,
	BrowserRouter as Router,
} from "react-router-dom";

import { ApiKey, Faq, TermsAndPolicies, PushNotifications, MobileUsers, PostLogin } from "../pages";
import { Menu } from "../components";
import { Error404 } from "../pages/Errors";
import { useTheme } from "../contexts/theme";
import { FEATURES, ROUTES } from "../constants";
import { CustomExercise, Firmware, Manual, SleepScore, Support, Workout, Tutorials, WatchFaces, ShareExercises, Gps, StoreLink } from "../pages/Dashboard";
import "../assets/styles/App.css";
import GlobalStyle from "../assets/styles/GlobalStyle";
import { AdminUsers } from "../pages/Settings";
import PermissionManager from "../services/auth/PermissionManager";

const MainRoutes = () => {

	const { currentTheme } = useTheme();

	return (
		<Router>
			<GlobalStyle theme={currentTheme} />
			<Routes>
				<Route path="/" element={<PostLogin/>} />
				<Route path={ROUTES.POST_LOGIN} element={<PostLogin />} />

				<Route path={ROUTES.ADMIN} element={<PermissionManager skipRoleCheck requestedFeature=""> <Menu renderAsPartial={true} isMainPage /> </PermissionManager>}>
					<Route path={ROUTES.DASHBOARD.MOBILE_USERS} element={<PermissionManager requestedFeature={FEATURES.MOBILE_USERS}>  <MobileUsers /> </PermissionManager>} />
					<Route path={ROUTES.DASHBOARD.PUSH_NOTIFICATIONS} element={<PermissionManager requestedFeature={FEATURES.PUSH_NOTIFICATIONS}> <PushNotifications/> </PermissionManager>} />
					<Route path={ROUTES.DASHBOARD.FAQ} element={<PermissionManager requestedFeature={FEATURES.FAQ}> <Faq /> </PermissionManager>} />
					<Route path={ROUTES.DASHBOARD.SUPPORT} element={<PermissionManager requestedFeature={FEATURES.SUPPORT}> <Support /> </PermissionManager>} />
					<Route path={ROUTES.DASHBOARD.TERMS_AND_CONDITIONS} element={<PermissionManager requestedFeature={FEATURES.TERMS_AND_CONDITIONS}> <TermsAndPolicies /> </PermissionManager>} />
					<Route path={ROUTES.DASHBOARD.API_KEY} element={<PermissionManager requestedFeature={FEATURES.API_KEY}> <ApiKey /> </PermissionManager>} />
					<Route path={ROUTES.DASHBOARD.WATCH_FACES} element={ <PermissionManager requestedFeature={FEATURES.WATCH_FACES}> <WatchFaces /> </PermissionManager>} />
					<Route path={ROUTES.DASHBOARD.TUTORIALS} element={<PermissionManager requestedFeature={FEATURES.TUTORIALS}> <Tutorials /> </PermissionManager>} />
					<Route path={ROUTES.DASHBOARD.MANUAL} element={<PermissionManager requestedFeature={FEATURES.MANUAL}>  <Manual /> </PermissionManager>} />
					<Route path={ROUTES.DASHBOARD.FIRMWARE} element={<PermissionManager requestedFeature={FEATURES.FIRMWARE}> <Firmware /> </PermissionManager>} />
					<Route path={ROUTES.DASHBOARD.SLEEP_SCORE} element={<PermissionManager requestedFeature={FEATURES.SLEEP_SCORE}> <SleepScore /> </PermissionManager>} />
					<Route path={ROUTES.DASHBOARD.TRAINING} element={<PermissionManager requestedFeature={FEATURES.TRAINING}> <Workout /> </PermissionManager>} />
					<Route path={ROUTES.DASHBOARD.SHARE_EXERCISE} element={<PermissionManager requestedFeature={FEATURES.SHARE_EXERCISE}> <ShareExercises /> </PermissionManager>} />
					<Route path={ROUTES.DASHBOARD.CUSTOM_EXERCISE} element={<PermissionManager requestedFeature={FEATURES.CUSTOM_EXERCISE}> <CustomExercise /> </PermissionManager>} />
					<Route path={ROUTES.DASHBOARD.GPS} element={<PermissionManager requestedFeature={FEATURES.GPS}> <Gps /> </PermissionManager>} />
					<Route path={ROUTES.DASHBOARD.STORE_LINK} element={<PermissionManager requestedFeature={FEATURES.STORE_LINK}> <StoreLink /> </PermissionManager>} />
				</Route>

				<Route path={ROUTES.SETTING} element={<PermissionManager skipRoleCheck requestedFeature=""> <Menu renderAsPartial={true} /> </PermissionManager>}>
					<Route path={ROUTES.SETTINGS.ADMIN_USERS} element={<PermissionManager requestedFeature={FEATURES.ADMIN_USERS}> <AdminUsers /> </PermissionManager>} />
				</Route>

				<Route path={ROUTES.ERROR_404} element={<Error404 />} />
				<Route path="*" element={<Error404 />} />
			</Routes>
		</Router>
	);
};

export default MainRoutes;