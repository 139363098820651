import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import { Container, InfosContainer } from "./styles";
import { Device } from "../../../../../../types/Device";
import { useTheme } from "../../../../../../contexts/theme";
import { useMobileApp } from "../../../../../../contexts/mobileApp";
import { DEFAULT_DEVICE } from "../../../../../../constants";

interface Props {
	onClick: (selectedApp: Device) => void;
	item: Device;
}

const DropdownItem = (props: Props) => {

	const { currentTheme } = useTheme();
	const { selectedApp } = useMobileApp();

	return (
		<Container
			theme={currentTheme}
			onClick={() => {
				props.onClick(props.item);
			}}>
			<div>
				<InfosContainer>
					{props.item.slug === DEFAULT_DEVICE.slug ? <img src={selectedApp.image ?? ""} /> :<img src={props.item.imageCDN ?? ""} /> }
					<p>{props.item.name}</p>
				</InfosContainer>
				<IoIosArrowForward color={currentTheme.secondaryColor} fontSize="1.5em" />
			</div>
		</Container>
	);
};

export default DropdownItem;
