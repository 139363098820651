import { getApi } from ".";
import { StoreLink } from "../../types/StoreLink";

export const getStoreLink = (appCode: number) => {
	return new Promise<StoreLink>((resolve, reject) => {
		getApi(appCode).get("/store_link")
			.then((response) => {
				resolve(response.data);
			}).catch((error) => {
				reject(error?.response?.data?.title);
			});
	});
};

export const updateStoreLink = (android: string, ios: string, appCode: number) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).post("store_link", { android, ios })
			.then(() => {
				resolve();
			}).catch((error) => {
				reject(error?.response?.data?.title);
			});
	});
};

export const getRedirectLink = (appCode: number) => {
	const api = getApi(appCode);
	return `${api.defaults.baseURL}/store_redirect`;
};
