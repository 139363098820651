import { useEffect, useState } from "react";
import { Container } from "./styles";
import { Loader } from "../../../../../../components";
import { useMobileApp } from "../../../../../../contexts/mobileApp";
import { GroupedFaqFeedback } from "../../../../../../types/FaqFeedback";
import { Helpers } from "../../../../../../helpers";
import { Api } from "../../../../../../services";
import CollapsableFaqFeedbackData from "./CollapsableFaqFeedbackData";


const FaqFeedback = () => {
	const { selectedApp } = useMobileApp();

	const [loading, setLoading] = useState(true);
	const [groupedFaqFeedback, setGroupedFaqFeedback] = useState<GroupedFaqFeedback[] | null>(null);

	useEffect(() => {
		let isMounted = true;

		Helpers.General.run(async () => {
			try {
				const groupedFaqFeedback = await Api.FaqFeedback.getGroupedFaqFeedback(selectedApp?.appCode);
				if (!isMounted)
					return;

				groupedFaqFeedback.sort((a, b) => a.themeOrder - b.themeOrder);
				setGroupedFaqFeedback(groupedFaqFeedback);
			} finally {
				if (isMounted)
					setLoading(false);
			}
		});

		return () => {
			isMounted = false;
		};
	}, []);

	const getContent = () => {
		if (loading)
			return <Loader />;
	
		if (!groupedFaqFeedback)
			return <p>Erro ao buscar dados do backend.</p>;
	
		if (groupedFaqFeedback.length === 0)
			return <p>Não há avaliações de FAQ de nenhum tema no momento.</p>;
		
		return groupedFaqFeedback.map((item) =>
			<CollapsableFaqFeedbackData key={item.themeId} groupedFaqFeedback={item} />
		);
	};


	return (
		<Container>
			{getContent()}
		</Container>
	);
};

export default FaqFeedback;