import { AdminUser, MobileUser, MobileApp, Device, Faq, Notification, WatchFace, Support, Terms, Firmware, Tutorial, ApiKeys, Training, CustomExercise, SleepScore, ShareExercise, Gps, AWS, FaqFeedback, StoreLink } from "./api";
import {doLogin, doLogout, getToken, getEmail, getId, hasRole, initKeycloak, isLoggedIn, updateToken} from "./auth";
import { LocalStorage, SessionStorage } from "./storage";

export const Api = {
	AdminUser,
	MobileUser,
	MobileApp,
	Device,
	Faq,
	FaqFeedback,
	Notification,
	WatchFace,
	Support,
	Terms,
	Firmware,
	Tutorial,
	ApiKeys,
	Training,
	CustomExercise,
	SleepScore,
	ShareExercise,
	Gps,
	StoreLink,
	AWS,
};

export const Auth = {
	doLogin,
	doLogout,
	getToken,
	getEmail,
	getId,
	hasRole,
	initKeycloak,
	isLoggedIn,
	updateToken
};

export const Storage = {
	LocalStorage,
	SessionStorage
};
