
export const MENU = {
	USERS: "Usuários",
	PUSH: "Push Notifications",
	FAQ: "Faq",
	LOGO: "Logotipo",
	EMAIL: "E-mail e suporte",
	TERMS: "Termos e políticas",
	API_KEY: "API key",
	WATCH_FACES: "Mostradores",
	TUTORIALS: "Vídeos tutoriais",
	MANUAL: "Manual",
	FIRMWARE: "Firmware",
	SLEEP_SCORE: "Score de sono",
	TRAIN: "Treino",
	SHARE_EXERCISE: "Compartilhar Exc.",
	CUSTOM_EXERCISE: "Exercício Cust.",
	GPS: "Gps",
	STORE_LINK: "Loja de Apps",
	SHOW_LESS: "Mostrar menos",
};
