import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	background-color: ${props => props.theme.backgroundCard};
	border-radius: 25px;
`;