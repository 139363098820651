
const ROUTES = {
	POST_LOGIN: "post_login",
	ADMIN: "admin",
	DASHBOARD: {
		MOBILE_USERS: "users",
		PUSH_NOTIFICATIONS: "push",
		FAQ: "faq",
		LOGO: "logo",
		SUPPORT: "email",
		TERMS_AND_CONDITIONS: "terms",
		API_KEY: "api-key",
		WATCH_FACES: "watch-faces",
		TUTORIALS: "tutorials",
		MANUAL: "manual",
		FIRMWARE: "firmware",
		SLEEP_SCORE: "sleep_score",
		TRAINING: "training",
		SHARE_EXERCISE: "share-exercise",
		CUSTOM_EXERCISE: "custom-exercise",
		GPS: "gps",
		STORE_LINK: "store-link",
	},
	SETTING: "setting",
	SETTINGS: {
		ADMIN_USERS: "admin_users",
	},
	ERROR_404: "404",
};

export default ROUTES;
