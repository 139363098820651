import React, { useRef, useState, useEffect } from "react";
import { useTheme } from "../../../../../../../contexts/theme";
import { MESSAGES } from "../../../../../../../constants/messages";
import { WatchFace, WatchFaceSection } from "../../../../../../../types/Watchface";
import { BtnContainer, Container, EmptyList, ImgContainer, ImgMask, PreviewCard, PreviewImg, RoudedBtnContainer, SectionContainer, SectionContent, SectionHeader, SectionLeft, SectionRight } from "./styles";
import { ReactComponent as DndIconLight } from "../../../../../../../assets/images/light/dndIcon.svg";
import { ReactComponent as DndIconDark } from "../../../../../../../assets/images/dark/dndIcon.svg";
import { ReactComponent as EditIconLight } from "../../../../../../../assets/images/light/editIcon.svg";
import { ReactComponent as TrashIcon } from "../../../../../../../assets/images/trashIcon.svg";
import { ReactComponent as HorizontalDnd } from "../../../../../../../assets/images/horizontalDnd.svg";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { FIXED_SECTION_NAME, THEME_TYPES } from "../../../../../../../constants";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import AddWatchFaceModal from "../../watchFaces/AddWatchFaceModal";
import DeleteItemModal, { ModalType } from "../../DeleteItemModal";
import EditSectionModal from "../EditSectionModal";
import EditWatchFaceModal from "../../watchFaces/EditWatchFaceModal";
import { toast } from "react-hot-toast";
import { useWatchFace } from "../../../../../../../contexts/watchFaces";
import { useMobileApp } from "../../../../../../../contexts/mobileApp";
import { AddButton, DeleteButton, EditButton } from "../../../../../../../components/Buttons";


interface WatchFaceItemProps {
	watchFaceSection: WatchFaceSection;
	userDisabledDrag: boolean;
	onDragDisabled: (dragEnabled: boolean) => void;
	onFeedbackRequested: (feedback) => void;
}

const SectionItem = (props: WatchFaceItemProps) => {

	const { currentTheme } = useTheme();
	const { saveNewWfListSortOrder } = useWatchFace();
	const { selectedApp } = useMobileApp();
	const scrollRef = useRef<HTMLDivElement | null>(null);
	const [items, setItems] = useState(props.watchFaceSection.watchFaces.sort((a, b) => a.index - b.index));

	const [deleteSectionModalVisible, setDeleteSectionModalVisible] = useState(false);
	const deleteTypeRef = useRef(ModalType.WATCH_FACE);

	const [editSectionModalVisible, setEditSectionModalVisible] = useState(false);

	const [addWatchFaceModalVisible, setAddWatchFaceModalVisible] = useState(false);
	const [editWatchFaceModalVisible, setEditWatchFaceModalVisible] = useState(false);
	const selectedWatchFaceRef = useRef<WatchFace | null>(null);

	useEffect(() => {
		setItems(props.watchFaceSection.watchFaces.sort((a, b) => a.index - b.index));
	}, [props.watchFaceSection]);

	useEffect(() => {
		props.onDragDisabled(addWatchFaceModalVisible);
	}, [addWatchFaceModalVisible]);

	const scroll = (scrollOffset: number) => {
		if (scrollRef.current) {
			scrollRef.current.scrollLeft += scrollOffset;
		}
	};

	const handleOnDragEnd = (result: DropResult) => {
		if (!result.destination || result.source.index === result.destination.index || props.watchFaceSection.watchFaces === null) return;

		const itemsArray = Array.from(items);
		const [reorderedItem] = itemsArray.splice(result.source.index, 1);
		itemsArray.splice(result.destination.index, 0, reorderedItem);
		setItems(itemsArray);
		itemsArray.forEach((item, index) => { item.index = index; });
		const t = toast.loading(MESSAGES.PT_BR.WATCH_FACE.WATCH_FACES_MESSAGES.LOADING);

		saveNewWfListSortOrder(selectedApp.appCode, itemsArray)
			.then(() => {
				toast.success(MESSAGES.PT_BR.WATCH_FACE.WATCH_FACES_MESSAGES.SUCCESS);
			}).catch(() => {
				toast.error(MESSAGES.PT_BR.WATCH_FACE.WATCH_FACES_MESSAGES.ERROR);
			}).finally(() => toast.dismiss(t));
	};

	return (
		<SectionContainer>
			<DeleteItemModal
				isOpen={deleteSectionModalVisible}
				onClose={(feedback) => {
					setDeleteSectionModalVisible(false);
					props.onFeedbackRequested(feedback);
				}}
				itemId={deleteTypeRef.current === ModalType.SECTION ? props.watchFaceSection.id : selectedWatchFaceRef.current?.id ?? -1}
				type={deleteTypeRef.current}
			/>
			{addWatchFaceModalVisible && <AddWatchFaceModal
				onClose={(feedback) => {
					setAddWatchFaceModalVisible(false);
					props.onFeedbackRequested(feedback);
				}}
				currentSection={props.watchFaceSection}
			/>}
			<EditSectionModal
				watchFaceSection={props.watchFaceSection}
				isOpen={editSectionModalVisible}
				onClose={(feedback) => {
					setEditSectionModalVisible(false);
					props.onFeedbackRequested(feedback);
				}} />
			{editWatchFaceModalVisible && <EditWatchFaceModal
				currentWatchFace={selectedWatchFaceRef.current}
				currentSection={props.watchFaceSection}
				onClose={(feedback) => {
					setEditWatchFaceModalVisible(false);
					props.onFeedbackRequested(feedback);
				}}
			/>}
			<SectionHeader>
				<SectionLeft>
					<ImgContainer>
						{currentTheme.type == THEME_TYPES.DARK ? <DndIconDark /> : <DndIconLight />}
					</ImgContainer>
					<h2>{props.watchFaceSection.sectionName ?? FIXED_SECTION_NAME}</h2>
				</SectionLeft>
				<SectionRight>
					<EditButton onClick={() => setEditSectionModalVisible(true)} />
					<DeleteButton onClick={() => {
						deleteTypeRef.current = ModalType.SECTION;
						setDeleteSectionModalVisible(true);
					}} />
					<AddButton onClick={() => setAddWatchFaceModalVisible(true)} />
				</SectionRight>
			</SectionHeader>
			<SectionContent>

				{
					items.length === 0 ?
						<EmptyList>{MESSAGES.PT_BR.WATCH_FACE.WATCH_FACES_MESSAGES.EMPTY_SECTION}</EmptyList>
						:
						<>
							<BtnContainer onClick={() => scroll(-150)}>
								<IoIosArrowBack size={30} color={currentTheme.textColor} />
							</BtnContainer>

							<DragDropContext onDragEnd={handleOnDragEnd}>
								<Droppable droppableId="sections" direction="horizontal">
									{(provided) => {
										return (
											<Container
												ref={node => {
													if (node) {
														provided.innerRef(node);
														scrollRef.current = node;
													}
												}}
												onMouseEnter={() => props.onDragDisabled(true)} onMouseLeave={() => props.onDragDisabled(false)} >
												{items.map((watchFace, index) => {
													return (
														<Draggable key={index} draggableId={index.toString()} index={index} isDragDisabled={props.userDisabledDrag} >
															{(provided) => (
																<PreviewCard ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
																	<PreviewImg src={watchFace.previewCDN ?? ""} alt="preview" />
																	<ImgMask >
																		<HorizontalDnd />
																		<div>
																			<RoudedBtnContainer style={{ marginRight: "5px" }} onClick={() => {
																				selectedWatchFaceRef.current = watchFace;
																				setEditWatchFaceModalVisible(true);
																			}}>
																				<EditIconLight />
																			</RoudedBtnContainer>
																			<RoudedBtnContainer onClick={() => {
																				selectedWatchFaceRef.current = watchFace;
																				deleteTypeRef.current = ModalType.WATCH_FACE;
																				setDeleteSectionModalVisible(true);
																			}}>
																				<TrashIcon />
																			</RoudedBtnContainer>
																		</div>
																		<HorizontalDnd style={{ opacity: 0 }} />
																	</ImgMask>
																</PreviewCard>
															)}
														</Draggable>
													);
												})}
												{provided.placeholder}
											</Container>
										);
									}}
								</Droppable>
							</DragDropContext>

							<BtnContainer onClick={() => scroll(150)} >
								<IoIosArrowForward size={30} color={currentTheme.textColor} />
							</BtnContainer>
						</>
				}
			</SectionContent>
		</SectionContainer >
	);
};

export default SectionItem;