import styled from "styled-components";
import { Theme } from "../../../../../../../types/Theme";

import { ReactComponent as ArrowDownLight } from "../../../../../../../assets/images/light/arrowDownIcon.svg";
import { ReactComponent as ArrowUpLight } from "../../../../../../../assets/images/light/arrowUpIcon.svg";
import { ReactComponent as ArrowUpDark } from "../../../../../../../assets/images/dark/arrowUpIcon.svg";
import { ReactComponent as ArrowDownDark } from "../../../../../../../assets/images/dark/arrowDownIcon.svg";
import SvgContainer, { SvgContainerProps } from "../../../../../../../components/Containers/SvgContainer";
import { THEME_TYPES } from "../../../../../../../constants";
import { Optional } from "../../../../../../../types/utils";
import { FC } from "react";

export const StackContainer = styled.div<ContainerProps>`
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: ${props => props.theme.backgroundCardSecondary};
	border-radius: 15px;
	margin-top: 10px;
`;

export const HeaderContainer = styled.div<ContainerProps>`
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 60px;
	padding: 24px;
	box-sizing: border-box;

	&:hover {
		cursor: pointer;
	}
`;

export const ImgContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding-left: 10px;
	padding-right: 10px;
	box-sizing: border-box;
`;

export const TextContainer = styled.div<ContainerProps>`
	flex: 1;
	display: flex;
	align-items: center;

	h2 {
		color: ${props => props.theme.textColor};
		font-size: 24px;
		font-style: italic;
		font-weight: bold;
	}
`;

export const ExpandedContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 24px;
	box-sizing: border-box;

	overflow-x: auto;
`;

export const TopicInfoTable = styled.table<TopicInfoTableProps>`
	text-align: center;

	td {
		min-width: 108px;
		width: 10%;
		padding: 8px 20px;
		font-size: 16px;
	}

	td:first-child {
		min-width: 300px;
		width: 60%;
	}

	td:last-child {
		text-align: right;
	}

	thead td {
		font-size: 20px;
		font-weight: bold;
		font-style: italic;
	}
	
	button {
		background-color: ${({ appTheme }) => appTheme.secondaryColor};
		color: ${({ appTheme }) => appTheme.backgroundCard};
		border: none;
		border-radius: 12px;
		font-size: 16px;
		width: 112px;
		height: 48px;

		&:hover {
			cursor: pointer;
		}
	}
`;

export const ArrowImageContainer = styled(SvgContainer as FC<Optional<SvgContainerProps, "SvgComponent">>).attrs<ArrowImageContainerProps>(({ appTheme, isExpanded }) => {
	if (isExpanded)
		return appTheme.type == THEME_TYPES.DARK ? { SvgComponent: ArrowUpDark } : { SvgComponent: ArrowUpLight };
	else
		return appTheme.type == THEME_TYPES.DARK ? { SvgComponent: ArrowDownDark } : { SvgComponent: ArrowDownLight };
})<ArrowImageContainerProps>`
	opacity: ${({ isEmpty }) => isEmpty ? 0.3 : 1};
`;

/**
 * Interfaces.
 */

interface TopicInfoTableProps {
	appTheme: Theme;
}

interface ContainerProps {
	theme: Theme;
}

type ArrowImageContainerProps = {
	appTheme: Theme;
	isExpanded: boolean;
	isEmpty: boolean;
}
