import React, { useState } from "react";
import { MESSAGES } from "../../../../../../constants/messages";
import { useTheme } from "../../../../../../contexts/theme";
import { useDevice } from "../../../../../../contexts/device";
import { ReactComponent as CloseIcon } from "../../../../../../assets/images/closeIcon.svg";
import { InfoInput, FileInput } from "../../../../../../components/Inputs";
import { MainButton } from "../../../../../../components/Buttons";
import { InfoBox, TechnosToggle, HorizontalDeviceSelection } from "../../../../../../components";
import { ShareExercise } from "../../../../../../types/ShareExercise";
import { ModalBody, ModalContainer, ModalContent, ModalFooter, ModalHeader, LeftSide, FilesContainer, RightSide, ImgContainer, RowContainer } from "../AddShareExerciseModal/styles";

interface Props {
	onClose: () => void;
	selectedShareExercise: ShareExercise;
	handleEdit: (shareExercise: ShareExercise, newImageFile: File) => void;
}

const EditShareExerciseModal = (props: Props) => {
	const { currentTheme } = useTheme();
	const { deviceListWithoutDefault } = useDevice();

	const [shareExercise, setShareExercise] = useState<ShareExercise>(props.selectedShareExercise);
	const [image, setImage] = useState<string>(shareExercise.image);
	const [newImageFile, setNewImageFile] = useState<File>(new File([], ""));
	const [nameError, setNameError] = useState<string>("");

	const handleEdit = () => {
		let allRight = true;

		if (shareExercise.name === "") {
			setNameError(MESSAGES.PT_BR.TRAINING_MESSAGES.MANDATORY_FIELD);
			allRight = false;
		} else {
			setNameError("");
		}

		if (allRight) {
			props.handleEdit(shareExercise, newImageFile);
			props.onClose();
		}
	};

	const handleOnChangeFile = (file: File) => {
		setNewImageFile(file);
		setImage(URL.createObjectURL(file));
	};
	
	return (
		<ModalContainer>
			<ModalContent>
				<ModalHeader>
					<h1>{MESSAGES.PT_BR.SHARE_EXERCISE.EDIT_TITLE}</h1>
					<div>
						<CloseIcon onClick={props.onClose} />
					</div>
				</ModalHeader>
				<ModalBody>
					<LeftSide>
						<InfoInput
							type="text"
							onChange={(newName) => setShareExercise({ ...shareExercise, name: newName })}
							placeholder={MESSAGES.PT_BR.SHARE_EXERCISE.NAME_PLACEHOLDER}
							value={shareExercise.name}
							title={MESSAGES.PT_BR.SHARE_EXERCISE.NAME}
							errorText={nameError}
							InfoBox={<InfoBox text={MESSAGES.PT_BR.SHARE_EXERCISE.NAME_INFO} />}
						/>
						<FilesContainer>
							<FileInput
								onChange={handleOnChangeFile}
								title={MESSAGES.PT_BR.SHARE_EXERCISE.IMAGE}
								accept="image/png, image/jpeg"
								value={image}
								InfoBox={<InfoBox style={{ height: "65px" }} text={MESSAGES.PT_BR.SHARE_EXERCISE.IMAGE_INFO} />}
							/>
						</FilesContainer>
					</LeftSide>
					<RightSide>
						<ImgContainer>
							<img src={newImageFile.size ? image : props.selectedShareExercise.imageCDN} />
						</ImgContainer>
						<TechnosToggle
							checked={shareExercise.showApp}
							defaultChecked={false}
							leftLabel={MESSAGES.PT_BR.SHARE_EXERCISE.SHOW_ON_APP}
							onChange={() => setShareExercise({ ...shareExercise, showApp: !shareExercise.showApp })}
						/>
					</RightSide>
				</ModalBody>
				<RowContainer>
					<HorizontalDeviceSelection
						deviceList={deviceListWithoutDefault}
						selectedDeviceSlugList={shareExercise.slugs ?? []}
						onSelectionChange={(selectedDevices) => setShareExercise({ ...shareExercise, slugs: selectedDevices })}
					/>
				</RowContainer>
				<ModalFooter>
					<MainButton
						enabled
						onClick={handleEdit}
						text={MESSAGES.PT_BR.SHARE_EXERCISE.EDIT_BTN}
						lightColor={currentTheme.primaryColor}
						darkColor={currentTheme.primaryColor}
					/>
				</ModalFooter>
			</ModalContent>
		</ModalContainer>
	);
};

export default EditShareExerciseModal;
