import styled from "styled-components";
import { Theme } from "../../../../../../../../../types/Theme";

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 18px;
  box-sizing: border-box;
  width: 100%;

  word-break: break-all;
  
  background-color: ${({ appTheme }) => appTheme.backgroundCardSecondary};
  border-radius: 16px;
`;

export const Header = styled.div<HeaderProps>`
  display: flex;
  flex-wrap: wrap;
  color: ${({ appTheme }) => appTheme.placeholderColor};
  font-family: "Gotham";
  font-weight: 300;
  font-size: 24px;
  
  div {
    &:after {
      content: "•";
      padding-left: 8px;
      padding-right: 8px;
    }

    &:last-child:after {
      content: "";
    }
  }
`;

export const Content = styled.div<ContentProps>`
  display: flex;
  color: ${({ appTheme }) => appTheme.textColor};
  font-family: "Gotham";
  font-weight: 300;
  font-size: 24px;
`;

export const Footer = styled.div<FooterProps>`
  display: flex;
  color: ${({ appTheme }) => appTheme.placeholderColor};
  font-family: "Gotham";
  font-weight: 300;
  font-size: 24px;
`;

/**
 * Interfaces.
 */

interface ContainerProps {
  appTheme: Theme;
}

interface HeaderProps {
  appTheme: Theme;
}

interface ContentProps {
  appTheme: Theme;
}

interface FooterProps {
  appTheme: Theme;
}