import styled, { keyframes } from "styled-components";
import { Theme } from "../../../../../../../../types/Theme";

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
`;

const opacity = keyframes`
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
`;

export const ModalContent = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  max-width: 1000px;
  height: 70%;
  max-height: 700px;
  min-width: 1200px;
  background-color: ${({ appTheme }) => appTheme.backgroundCard};
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  animation: ${opacity} 0.3s linear;
`;

export const InternalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  gap: 16px;
  width: 100%;
  box-sizing: border-box;
`;

export const ModalHeader = styled.div<ContentProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;

  h1 {
    font-size: 40px;
    padding: 10px;
    text-align: center;
    font-family: "Gotham";
    font-weight: 400;
    line-height: 30px;
    color: ${({ appTheme }) => appTheme.primaryColor};
  }

  div {
    &:hover {
      cursor: pointer;
    }
  }
`;

/**
 * Interfaces.
 */

interface ContentProps {
  appTheme: Theme,
}