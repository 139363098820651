import React, { useEffect, useState } from "react";
import { ReactComponent as CloseIcon } from "../../../assets/images/closeIcon.svg";
import { useDevice } from "../../../contexts/device";
import { MESSAGES } from "../../../constants/messages";
import { ModalBody, ModalContainer, ModalContent, ModalHeader, ScrollContainer } from "./styles";
import { Device } from "../../../types/Device";
import { DeviceItem } from "./components";

interface ModalProps {
	onCancel: () => void;
}

const ShowDevicesModal = (props: ModalProps) => {
	const { deviceListWithoutDefault } = useDevice();
	const [filteredDevices, setFilteredDevices] = useState<Device[]>([]);

	useEffect(() => {
		setFilteredDevices(deviceListWithoutDefault);
	}, [deviceListWithoutDefault]);

	return (
		<ModalContainer>
			<ModalContent>
				<ModalHeader>
					<h1>{MESSAGES.PT_BR.DEVICE_MODAL.EDIT_DEVICE}</h1>
					<div>
						<CloseIcon onClick={props.onCancel} />
					</div>
				</ModalHeader>
				<ModalBody>
					<ScrollContainer>
						{filteredDevices.map((device, index) => (
							<DeviceItem 
								device={device} 
								onRemoveFailed={(device) => setFilteredDevices([...filteredDevices, device])}
								onRemoveRequested={(device) => {setFilteredDevices(filteredDevices.filter((d) => d.slug !== device.slug));}}
								key={index} />
						))}
					</ScrollContainer>
				</ModalBody>
			</ModalContent>
		</ModalContainer>
	);

};

export default ShowDevicesModal;