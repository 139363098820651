import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.backgroundCard};
    border-radius: 24px;
    padding: 32px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
`;

export const Header = styled.div`
	width: 100%;
	margin-bottom: 32px;

	h1 {
		font-weight: 500;
		font-size: 32px;
		color: ${props => props.theme.textColor};
	}
`;

export const ContentFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
	gap: 16px;
	align-items: flex-end;

    button {
        width: 30%;
        max-width: 300px;
    }	
`;
