import React, { useState, useEffect } from "react";
import { ModalBody, ModalContainer, ModalContent, ModalFooter, ModalHeader, RowContainer } from "./styles";
import { ReactComponent as CloseIcon } from "../../../../../../../../../../assets/images/closeIcon.svg";
import { useTheme } from "../../../../../../../../../../contexts/theme";
import { MESSAGES } from "../../../../../../../../../../constants/messages";
import { InputWithSuggestion, MainInput } from "../../../../../../../../../../components/Inputs";
import { MainButton } from "../../../../../../../../../../components/Buttons";
import { HorizontalDeviceSelection } from "../../../../../../../../../../components";
import { FaqArticle, FaqTopic } from "../../../../../../../../../../types/FaqArticle";
import { useDevice } from "../../../../../../../../../../contexts/device";
import { Helpers } from "../../../../../../../../../../helpers";
import { useFaq } from "../../../../../../../../../../contexts/faq";
import { toast } from "react-hot-toast";
import { useMobileApp } from "../../../../../../../../../../contexts/mobileApp";

interface Props {
	isOpen: boolean;
	onClose: (feedback) => void;
	faqTopic: FaqTopic;
	faqArticle: FaqArticle;

}

const EditTopicModal = (props: Props) => {

	const { currentTheme } = useTheme();
	const { deviceListWithoutDefault } = useDevice();
	const { selectedApp } = useMobileApp();
	const { faqList, updateFaqTopic, addLocalTag } = useFaq();

	const [availableTags, setAvailableTags] = useState<string[]>(Helpers.Faq.getAvailableTags(faqList));

	const [currentTopic, setCurrentTopic] = useState<FaqTopic>(props.faqTopic);
	const [topicError, setTopicError] = useState("");
	const [contentError, setContentError] = useState("");
	const [tagsError, setTagsError] = useState("");

	useEffect(() => {
		resetStates();
	}, [props.faqTopic, faqList]);

	const handleAddTag = (newTag: string[]) => {
		if (newTag.length > 0) {
			addLocalTag(newTag[newTag.length - 1]);
		}

		setCurrentTopic({ ...currentTopic, tags: newTag });
	};

	const handleUpdateTopic = () => {

		let allRight = true;
		if (currentTopic.topic === undefined || currentTopic.topic === "") {
			setTopicError(MESSAGES.PT_BR.FAQ_MESSAGES.MANDATORY_FIELD);
			allRight = false;
		} else {
			setTopicError("");
		}

		if (currentTopic.content === undefined || currentTopic.content === "") {
			setContentError(MESSAGES.PT_BR.FAQ_MESSAGES.MANDATORY_FIELD);
			allRight = false;
		} else {
			setContentError("");
		}

		if(currentTopic.tags === undefined || currentTopic.tags.length === 0) {
			setTagsError(MESSAGES.PT_BR.FAQ_MESSAGES.MANDATORY_FIELD);
			allRight = false;
		} else {
			setTagsError("");
		}

		if (allRight) {
			const t = toast.loading(MESSAGES.PT_BR.FAQ_MESSAGES.LOADING);
			updateFaqTopic(selectedApp.appCode ?? 0, currentTopic, props.faqArticle.id)
				.then(() => {
					props.onClose({ isOpen: true, title: MESSAGES.PT_BR.FAQ_MESSAGES.SUCCESS_UPDATE_TOPIC, description: MESSAGES.PT_BR.FAQ_MESSAGES.SUCCESS_UPDATE_TOPIC_DESCRIPTION, success: true });
				}).catch((error) => {
					props.onClose({ isOpen: true, title: MESSAGES.PT_BR.FAQ_MESSAGES.ERROR_UPDATE_TOPIC, description: MESSAGES.PT_BR.FAQ_MESSAGES.ERROR_UPDATE_TOPIC_DESCRIPTION + error, success: false });
				}).finally(() => {
					toast.dismiss(t);
				});
		}
	};

	const resetStates = () => {
		setCurrentTopic(props.faqTopic);
		setAvailableTags(Helpers.Faq.getAvailableTags(faqList));
		setTagsError("");
		setTopicError("");
		setContentError("");
	};

	return (
		<ModalContainer isOpen={props.isOpen} theme={currentTheme}>
			<ModalContent theme={currentTheme}>
				<ModalHeader theme={currentTheme}>
					<h1>{MESSAGES.PT_BR.FAQ_MESSAGES.EDIT_ARTICLE}</h1>
					<div>
						<CloseIcon onClick={() => props.onClose(null)} />
					</div>
				</ModalHeader>
				<ModalBody>
					<RowContainer>
						<MainInput
							disabled
							type="text"
							title={MESSAGES.PT_BR.FAQ_MESSAGES.THEME}
							value={props.faqArticle.theme}
							onChange={() => null}
							placeholder={MESSAGES.PT_BR.FAQ_MESSAGES.THEME_PLACEHOLDER}
						/>
						<h5></h5>
						<MainInput
							type="text"
							title={MESSAGES.PT_BR.FAQ_MESSAGES.TOPIC}
							value={currentTopic.topic}
							placeholder={MESSAGES.PT_BR.FAQ_MESSAGES.TOPIC_PLACEHOLDER}
							onChange={(value) => { setCurrentTopic({ ...currentTopic, topic: value }); }}
							errorText={topicError}
						/>
					</RowContainer>
					<MainInput
						type="text"
						title={MESSAGES.PT_BR.FAQ_MESSAGES.CONTENT}
						value={currentTopic.content}
						placeholder={MESSAGES.PT_BR.FAQ_MESSAGES.CONTENT_PLACEHOLDER}
						onChange={(value) => { setCurrentTopic({ ...currentTopic, content: value }); }}
						rowsNumber={3}
						errorText={contentError}
					/>
					<InputWithSuggestion
						isMulti
						title={MESSAGES.PT_BR.FAQ_MESSAGES.TAGS}
						createItemText={MESSAGES.PT_BR.FAQ_MESSAGES.CREATE_TAG}
						onAddItemRequested={(newTag) => { handleAddTag(newTag); }}
						availableValues={availableTags}
						values={currentTopic.tags ?? []}
						placeholder={MESSAGES.PT_BR.FAQ_MESSAGES.CONTENT_PLACEHOLDER}
						errorText={tagsError}
					/>
					<ModalFooter theme={currentTheme}>
						<HorizontalDeviceSelection
							deviceList={deviceListWithoutDefault}
							selectedDeviceSlugList={currentTopic.devices ?? []}
							onSelectionChange={(selectedDevices) => { setCurrentTopic({ ...currentTopic, devices: selectedDevices }); }}
						/>
						<MainButton enabled onClick={() => handleUpdateTopic()}text={MESSAGES.PT_BR.FAQ_MESSAGES.SAVE_BTN} />
					</ModalFooter>
				</ModalBody>
			</ModalContent>
		</ModalContainer>
	);
};

export default EditTopicModal;
