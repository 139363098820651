import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { MESSAGES } from "../../../../../constants/messages";
import { useDevice } from "../../../../../contexts/device";
import { useMobileApp } from "../../../../../contexts/mobileApp";
import { useTheme } from "../../../../../contexts/theme";
import { Device } from "../../../../../types/Device";
import { DeleteButton, EditButton } from "../../../../Buttons";
import DeleteItemModal from "../../../DeleteItemModal";
import EditDeviceModal from "../../../EditDeviceModal";
import StatusModal from "../../../StatusModal";
import { ConfirmDeleteToaster, DeviceContainer, EditContainer, ImgContainer, InputStyled } from "./styles";

interface DeviceItemProps {
    device: Device;
    onRemoveRequested: (device: Device) => void;
    onRemoveFailed: (device: Device) => void;
}

const DeviceItem = (props: DeviceItemProps) => {

	const { currentTheme } = useTheme();
	const { selectedApp } = useMobileApp();
	const { deleteDevice } = useDevice();
	const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
	const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
	const [statusModal, setStatusModal] = useState({ isOpen: false, title: "", description: "", success: false });

	const onNeedShowStatus = (feedback) => {
		setStatusModal(feedback);
		setEditModalVisible(false);

		setTimeout(() => {
			setStatusModal({ ...statusModal, isOpen: false });
		}, 3000);
	};


	const handleDeleteDevice = () => {
		setDeleteModalVisible(false);
		props.onRemoveRequested(props.device);

		const deleteTimeout = setTimeout(() => {
			deleteDevice(props.device, selectedApp?.appCode || 0)
				.catch((err) => {
					toast.error(MESSAGES.PT_BR.DEVICE_MODAL.DEVICE_DELETED_ERROR + err);
					props.onRemoveFailed(props.device);
				});
		}, 4500);

		const cancelDelete = (t) => {
			clearTimeout(deleteTimeout);
			props.onRemoveFailed(props.device);
			toast.dismiss(t.id);
		};

		toast(
			(t) => (
				<ConfirmDeleteToaster theme={currentTheme}>
					<span>{MESSAGES.PT_BR.DEVICE_MODAL.DEVICE_DELETED}&emsp;</span>
					<b onClick={() => { cancelDelete(t); }} >
						{MESSAGES.PT_BR.DEVICE_MODAL.UNDO_DELETE}
					</b>
				</ConfirmDeleteToaster>
			)
		);
	};

	return (
		<DeviceContainer theme={currentTheme} >
			<StatusModal isOpen={statusModal.isOpen} title={statusModal.title} description={statusModal.description} success={statusModal.success} />
			<DeleteItemModal
				isOpen={deleteModalVisible}
				onCancel={() => setDeleteModalVisible(false)}
				title={MESSAGES.PT_BR.DEVICE_MODAL.DELETE_DEVICE_TITLE}
				body={MESSAGES.PT_BR.DEVICE_MODAL.DELETE_DEVICE_DESCRIPTION}
				handleDelete={handleDeleteDevice}
			/>
			{editModalVisible && <EditDeviceModal
				onCancel={() => setEditModalVisible(false)}
				currentDevice={props.device}
				onClose={(feedback) => {onNeedShowStatus(feedback);}}
			/>}
			<img src={props.device?.imageCDN} alt={"Watch Logo"} />
			<EditContainer theme={currentTheme}>
				<div>
					<h1>Nome do Dispositivo</h1>
					<ImgContainer>
						<EditButton onClick={() => {setEditModalVisible(true);}} />
						<DeleteButton onClick={() => { setDeleteModalVisible(true); }} />
					</ImgContainer>
				</div>
				<InputStyled
					theme={currentTheme}
					value={props.device.name}
					disabled
				/>
			</EditContainer>
		</DeviceContainer>
	);
};

export default DeviceItem;