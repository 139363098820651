import React, { useState } from "react";
import { MESSAGES } from "../../../../constants/messages";
import { useTheme } from "../../../../contexts/theme";
import FaqThemes from "./components/FaqThemes";
import FaqFeedback from "./components/FaqFeedback";
import { HeaderOptionsContainer, HeaderOption, Content } from "./styles";


const Faq = () => {

	const HEADER_ITEMS = [
		MESSAGES.PT_BR.FAQ_MESSAGES.THEME_TITLE,
		MESSAGES.PT_BR.FAQ_MESSAGES.EVALUATION_TITLE
	];
	
	const { currentTheme } = useTheme();
	const [selectedTab, setSelectedTab] = useState(HEADER_ITEMS[0]);

	const getContentByTab = () => {
		switch (selectedTab) {
		case MESSAGES.PT_BR.FAQ_MESSAGES.EVALUATION_TITLE:
			return <FaqFeedback />;
		case MESSAGES.PT_BR.FAQ_MESSAGES.THEME_TITLE:
		default:
			return <FaqThemes />;
		}
	};


	return (
		<div>
			<HeaderOptionsContainer theme={currentTheme}>
				{HEADER_ITEMS.map((item, index) => (
					<HeaderOption
						key={index}
						theme={currentTheme}
						selected={selectedTab === item}
						onClick={() => setSelectedTab(item)}>
						<h5>{item}</h5>
					</HeaderOption>
				))}
			</HeaderOptionsContainer>
			<Content theme={currentTheme}>
				{getContentByTab()}
			</Content>
		</div>
	);
};

export default Faq;