import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { FiLink } from "react-icons/fi";
import { MainInput } from "../../../../components/Inputs";
import { MainButton, TextIconButton } from "../../../../components/Buttons";
import { COLORS } from "../../../../assets";
import { useMobileApp } from "../../../../contexts/mobileApp";
import { useStoreLink } from "../../../../contexts/storeLink";
import { Container, Header, ContentFooter } from "./styles";

const StoreLink = () => {
	const { selectedApp } = useMobileApp();
	const { getStoreLink, updateStoreLink, getRedirectLink } = useStoreLink();
	
	const [androidLink, setAndroidLink] = useState("");
	const [iosLink, setIosLink] = useState("");
	const [androidLinkError, setAndroidLinkError] = useState("");
	const [iosLinkError, setIosLinkError] = useState("");

	useEffect(() => {
		getStoreLink(selectedApp.appCode).then(links => {
			setAndroidLink(links.android);
			setIosLink(links.ios);
		});
	}, []);

	const isGooglePlayLinkValid = (link: string) => {
		// https://play.google.com/store/apps/details?id=com.example.app
		const googlePlayRegex = /^https:\/\/play\.google\.com\/store\/apps\/details\?id=[a-zA-Z0-9._-]+(&.*)?$/;
		return googlePlayRegex.test(link);
	};

	const isAppStoreLinkValid = (link: string) => {
		// https://apps.apple.com/{country}/app/{app-name}/id{app-id}
		const appStoreRegex = /^https:\/\/apps\.apple\.com\/[a-z]{2}\/app\/[a-zA-Z0-9\-._~%]+\/id\d+$/;
		return appStoreRegex.test(link);
	};

	const handleSaveStoreLinks = () => {
		let allRight = true;

		if (!isGooglePlayLinkValid(androidLink)) {
			setAndroidLinkError("Link da Google Play inválido.");
			allRight = false;
		} else {
			setAndroidLinkError("");
		}
		
		if (!isAppStoreLinkValid(iosLink)) {
			setIosLinkError("Link da App Store inválido.");
			allRight = false;
		} else {
			setIosLinkError("");
		}
		
		if (!allRight) return;

		updateStoreLink(androidLink, iosLink, selectedApp.appCode).then(() => {
			toast.success("Salvo com sucesso.");
		}).catch(error => {
			toast.error("Erro ao salvar: " + error);
		});
	};

	const copyRedirectLink = () => {
		const redirectLink = getRedirectLink(selectedApp.appCode);
		navigator.clipboard.writeText(redirectLink);
		toast.success("Link de redirecionamento copiado.");
	};

	return (
		<Container>
			<Header>
				<h1>Links</h1>
			</Header>
			<MainInput
				title={"Android"}
				value={androidLink}
				onChange={setAndroidLink}
				placeholder={"Google Play link"}
				type="url"
				style={{ marginBottom: 28 }}
				errorText={androidLinkError}
			/>
			<MainInput
				title={"iOS"}
				value={iosLink}
				onChange={setIosLink}
				placeholder={"App Store link"}
				type="url"
				errorText={iosLinkError}
			/>
			<ContentFooter>
				<TextIconButton
					enabled
					text={"Copiar link"}
					onClick={copyRedirectLink}
					svgImage={<FiLink style={{ "width": "100%", "height": "100%" }} />}
					darkColor={COLORS.DEFAULT_COLORS.SECOND_BUTTON}
					lightColor={COLORS.DEFAULT_COLORS.SECOND_BUTTON}
				/>
				<MainButton
					enabled={androidLink !== "" && iosLink !== ""}
					text={"Salvar"}
					onClick={handleSaveStoreLinks}
					darkColor={COLORS.DEFAULT_COLORS.FIRST_BUTTON}
					lightColor={COLORS.DEFAULT_COLORS.FIRST_BUTTON}
				/>
			</ContentFooter>
		</Container>
	);
};

export default StoreLink;