import { WorkoutLevel } from "../Training";


const fromWorkoutVideoToApi = (appObj) => {
	return ({
		id: appObj.id ?? -1,
		TrainingVideoSectionId: appObj.trainingVideoSectionId,
		Available: appObj.available,
		Difficulty: appObj.difficulty,
		Episode: appObj.episode,
		SecondsDuration: appObj.secondsDuration,
		Description: appObj.description,
		Link: appObj.link,
		Thumbnail: appObj.thumbnail,
		slugs: appObj.slugs
	});
};

const fromWorkoutVideoSectionToApi = (workoutAppObj) => {
	return (
		{
			id: workoutAppObj.id,
			Name: workoutAppObj.name,
			Icon: workoutAppObj.icon,
			IconSelected: workoutAppObj.iconSelected,
			BackgroundImage: workoutAppObj.backgroundImage ?? null,
			ExerciseType: workoutAppObj.exerciseType,
			Index: workoutAppObj.index,
		}
	);
};


const fromApiSectionToWorkoutSection = (apiObj) => {
	return (
		{
			id: apiObj.id,
			name: apiObj.name,
			exerciseType: apiObj.exercise_type,
			icon: apiObj.iconS3, // manter icon com a url do S3 para não alterar valores no banco quando fizer update!
			iconCDN: apiObj.icon, // consumir imagem da CDN
			iconSelected: apiObj.iconSelectedS3,
			iconSelectedCDN: apiObj.iconSelected,
			backgroundImage: apiObj.background_image,
			index: apiObj.index,
			trainingVideos: apiObj.videos?.map((apiVideo) => __fromApiVideoToWorkoutVideo(apiVideo, apiObj.id))
		}
	);
};

const __fromApiVideoToWorkoutVideo = (apiVideo, sectionId) => {
	return ({
		id: apiVideo.id,
		difficulty: __getDifficultyFromApi(apiVideo.difficult),
		episode: apiVideo.episode,
		secondsDuration: apiVideo.seconds_duration,
		description: apiVideo.description,
		link: apiVideo.link,
		thumbnail: apiVideo.thumbnailS3,
		thumbnailCDN: apiVideo.thumbnail,
		available: apiVideo.available,
		trainingVideoSectionId: sectionId,
		slugs: apiVideo.slugs ?? []
	});
};

const __getDifficultyFromApi = (difficulty) => {
	switch (difficulty) {
	case "BEGINNER":
		return WorkoutLevel.BEGINNER;
	case "INTERMEDIATE":
		return WorkoutLevel.INTERMEDIATE;
	case "ADVANCED":
		return WorkoutLevel.ADVANCED;
	}
};

export const TrainingVideoMapper = {
	fromWorkoutVideoToApi,
	fromWorkoutVideoSectionToApi,
	fromApiSectionToWorkoutSection
};
