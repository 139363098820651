import React, { useState } from "react";
import { ImgContainer, ItemContainer, StackContainer, TextContainer, WorkoutImage } from "./styles";
import { ReactComponent as DndIconLight } from "../../../../../../assets/images/light/dndIcon.svg";
import { ReactComponent as DndIconDark } from "../../../../../../assets/images/dark/dndIcon.svg";
import { useTheme } from "../../../../../../contexts/theme";
import { THEME_TYPES } from "../../../../../../constants";
import { ShareExercise } from "../../../../../../types/ShareExercise";
import { DeleteButton, EditButton } from "../../../../../../components/Buttons";

interface ShareExerciseItemProps {
	isDragDisabled: boolean;
    currentShareExercise: ShareExercise;
	onEditRequested: () => void;
	onDeleteRequested: () => void;
}

const ShareExerciseItem = (props: ShareExerciseItemProps) => {
	const { currentTheme } = useTheme();
	
	const [isDragging, setIsDragging] = useState(false);

	return (
		<StackContainer isDragging={isDragging && !props.isDragDisabled}>
			<ItemContainer
				onMouseDownCapture={() => setIsDragging(true)}
				onMouseOverCapture={() => setIsDragging(false)}
			>
				<ImgContainer>
					{currentTheme.type == THEME_TYPES.DARK ? <DndIconDark /> : <DndIconLight />}
				</ImgContainer>
				<WorkoutImage>
					<img src={props.currentShareExercise.imageCDN}></img>
				</WorkoutImage>
				<TextContainer>
					<p>{props.currentShareExercise.name}</p>
				</TextContainer>
				<ImgContainer >
					<EditButton onClick={props.onEditRequested} />
				</ImgContainer>
				<ImgContainer >
					<DeleteButton onClick={props.onDeleteRequested} />
				</ImgContainer>
			</ItemContainer>
		</StackContainer>
	);
};

export default ShareExerciseItem;