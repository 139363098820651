import React, { useState, useRef, Ref, useImperativeHandle } from "react";
import { TbFilter } from "react-icons/tb";
import { useTheme } from "../../../contexts/theme";
import { TextIconButton } from "../../Buttons";
import { StackContainer, Container, Text, OneLineText, DropdownItem, ItemsContainer, ItemContainer, Spacing, Image, Input, SaveContainer, SelectedCountContainer, SelectedCount } from "./styles";

export interface SelectDropdownRef {
	clearSelection: () => void;
}

interface OwnsProps {
	forwardedRef: Ref<SelectDropdownRef>;
	placeholder: string;
	items: string[];
	itemImages?: string[];
	onSave: (items: string[]) => void;
	style?: React.CSSProperties;
}

const SelectDropdown = (props: OwnsProps) => {
	const { currentTheme } = useTheme();

	const [isOpen, setIsOpen] = useState(false);
	const [render, setRender] = useState(false);
	const [selectedLabels, setSelectedLabels] = useState<string[]>([]);

	const itemsRef = useRef(
		props.items.map((value, index) => {
			return {
				label: value,
				image: props.itemImages ? props.itemImages[index] : "",
				checked: false
			};
		})
	);

	useImperativeHandle(props.forwardedRef, () => ({
		clearSelection:() => {
			itemsRef.current.forEach(item => item.checked = false);
			setSelectedLabels([]);
		},
	}), []);

	const onSave = () => {
		props.onSave(selectedLabels);
		setIsOpen(false);
	};

	const checkAtIndex = (index: number) => {
		const currentItem = itemsRef.current[index];
		const currentChecked = currentItem.checked;

		if (currentChecked) {
			const newSelectedLabels = selectedLabels.filter(label => label !== currentItem.label);
			setSelectedLabels(newSelectedLabels);
		} else {
			setSelectedLabels([...selectedLabels, currentItem.label]);
		}
			
		itemsRef.current[index].checked = !currentChecked;
		setRender(!render);
	};

	const getSelectedText = () => {
		if (selectedLabels.length) return selectedLabels.toString();
		return props.placeholder;
	};

	const getSelectedImage = () => {
		const selectedCount = selectedLabels.length;
		
		if (selectedCount === 0)
			return <TbFilter color={currentTheme.secondaryColor} fontSize="2.5em" style={{marginRight: "3px", marginLeft: "5px" }} />;

		if (props.itemImages === undefined)
			return null;
		
		const firstSelectedImage = itemsRef.current.find(item => item.label === selectedLabels[0])?.image;
		if (selectedCount === 1)
			return <Image src={firstSelectedImage}/>;

		return (
			<SelectedCountContainer>
				<SelectedCount>{selectedCount}</SelectedCount>
				<Image src={firstSelectedImage}/>				
			</SelectedCountContainer>
		);
	};

	const getOpenContent = () => {
		return (
			<Spacing>
				<ItemsContainer >
					{itemsRef.current.map((item, index) => {
						return (
							<DropdownItem key={index} onClick={() => checkAtIndex(index)} >
								<ItemContainer>
									{(item.image) && <Image src={item.image}/>}
									<Text>{item.label}</Text>
								</ItemContainer>
								<Input checked={item.checked} readOnly />
							</DropdownItem>
						);
					})}
					<SaveContainer>
						<TextIconButton enabled text="Salvar" onClick={onSave} />
					</SaveContainer>
				</ItemsContainer>
			</Spacing>
		);
	};

	return (
		<StackContainer style={props?.style}>
			<Container onClick={() => setIsOpen(!isOpen)}>
				{getSelectedImage()}
				<OneLineText>{getSelectedText()}</OneLineText>
			</Container>
			{isOpen && getOpenContent()}
		</StackContainer>
	);
};

export default SelectDropdown;